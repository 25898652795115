<script>
import List from '@/components/List/List.vue'
import Form from '@/components/Form/Form.vue'
import PopUp from '@/components/PopUp.vue'
import ToAdmin from '@/components/ToAdmin.vue'
import message from '@/helpers/message'
import UserInstruction from '@/components/UserInstruction.vue'
import AdminCoursesInstructors from '@/views/Admin/AdminCoursesInstructors.vue'
import AdminCoursesLocations from '@/views/Admin/AdminCoursesLocations.vue'

export default {
    components:{ List, Form, PopUp, ToAdmin, UserInstruction,AdminCoursesInstructors, AdminCoursesLocations },
    created() {  
        if(this.user.user_level != 'SamfallifyCrew' && this.user.user_level != 'Redaktör')
        {
            this.$router.push('/login');
        } 
        this.$cache.createCache('accountSettings', 'account-settings/'); 
        this.getCourses();  
    },
    data() {
        return {             
            sorting: [
                { name: 'name', displayName: 'Namm' },           
                { name: 'created_date', displayName: 'Skapad' },           
                { name: 'created_by_full_name', displayName: 'Skapad Av' },           
            ],
            courses: null,
            instructors: [],
            locations: [],
               
        }
    },   
    methods:
    {
        edit(item)
        {
            this.$router.push('/admin/courses/' + item.id);   
        },
        add()
        {
            this.$router.push('/admin/courses/add');          
        },
        delete(id)
        {
            var self = this;
            this.$communicator.post('courses/' + id + '/delete')
            .then(function(data)
            {                 
                self.getCourses();
                message.setSuccessMessage();
            })
            .fail(function(error) {                  
            })  
        },
        getCourses()
        {
            var self = this;
            this.$communicator.get('admin/courses/')
            .then(function(data)
            {                 
                self.courses =  data.courses;                     
                self.instructors =  data.course_instructors;                     
                self.locations =  data.course_locations;                     
            })
            .fail(function(error) {                  
            })     
        },
    },
    computed:
    {
        user() {return this.$store.state.user; },
        accountSettings() {return this.$store.state.accountSettings; },      
        settings() {return this.$store.state.settings; },      
        columns() { 
            var cols = [                             
                { name: 'edit', displayName: '<i class="fa-solid fa-pencil color-black"></i>', title:'Redigera', icon: 'fa-solid fa-pencil', align:'center', function: this.edit, width: 25, hideFilter: true, hideIfEdit: true},    
                { name: 'delete', displayName: '<i class="fa-solid fa-trash color-black"></i>',  title: 'Ta bort', isDelete: true, align:'center', width: 25, hideFilter: true, hideIfEdit: true},                
                { name: 'name', displayName: 'Namn', width: 200, hideFilter: false},
                { name: 'description', displayName: 'Beskrivning', width: 400, hideFilter: true},
               
                { name: 'formatted_created_date', displayName: 'Skapad', width: 100, hideFilter: true},
                { name: 'created_by_full_name', displayName: 'Skapad Av', width: 200},            
            ] 
            
            if(this.$store.state.accountSettings != null && this.$store.state.accountSettings.enable_public_page)
            {
                cols.splice(4,0,{ name: 'is_public_course', isBoolean: true, displayName: '<i class="fa-solid fa-globe color-black"></i>', excelName: 'Visas även på den publika sidan', title:'Visas även på den publika sidan', width: 25, align: 'center'});
            }
            return cols;
        }       
    }
}
</script>
<template>
    <div v-fade-in  v-if="accountSettings != null && courses && settings" style="display:flex">
        <main class="opacity-admin-bg overflow-auto instuctor-and-location-aside">
            <AdminCoursesInstructors :instructors="instructors" :getCourses="getCourses"></AdminCoursesInstructors>
            <AdminCoursesLocations :locations="locations" :getCourses="getCourses"></AdminCoursesLocations>
        </main>
        <main class="opacity-admin-bg overflow-auto position-relative" style="width: calc(75% - 44px); background-color: rgba(250, 233, 197, 0.98);" v-fade-in>
            <div class="float-right" v-show="instructors.length > 0">
                <button id="List-sort-btn" class="btn" @click="add">Lägg till en kurs</button>
                <!-- <UserInstruction :page="this.$route.name"></UserInstruction> -->
            </div>
            <ToAdmin :section="'Kurser'"></ToAdmin>
            <div v-if="instructors.length == 0">              
                <div class="info-box">
                    <p class="margin-bottom-15">
                        För att kunna lägga till kurser behöver du först lägga till minst en {{settings.CourseInstructor.singular }}. 
                        Detta är ett obligatoriskt steg för att kunna skapa och hantera kurser.
                    </p>
                    <p class="margin-bottom-15">
                        Det är även en bra idé att lägga till en plats för kursen, även om detta inte är ett krav. 
                        Genom att ange en plats får du möjligheten att samköra schemat för flera kurser som hålls 
                        på samma plats, vilket kan göra planeringen enklare och mer effektiv.
                    </p>
                    <p class="margin-bottom-15">
                        Tänk på att utan en plats kan det bli svårare att hantera scheman för flera kurser samtidigt.
                    </p>
                </div>
            </div>
            <div v-else v-fade-in>            
                <h2>Kurser</h2>               
                <List :items="courses" :columns="columns" :noOfFrozenColumns="3" :deleteFunction="delete" :nameOfItems="'kurser'" :sorting="sorting" :backgroundColor="this.$colors.list.backgroundColor" :borderColor="this.$colors.list.borderColor"></List>
            </div>
                 
        </main>
    </div>  
</template>
<style scoped>
    .instuctor-and-location-aside {
        width: calc(25% - 87px);
        margin-right: -34px;
        background-color: rgba(250, 233, 197, 0.94);
        margin-top: 99px;
        margin-bottom: 8px;
    }

    .AdminCourses-instructor i {
        color: rgb(225 216 130);
    }

    .info-box{
        margin-top: 30px;
    }
    
    .AdminCourses-instructor {
        border-radius: 5px;
        width: calc(50% - 28px);
        text-align: center;
        border: 1px solid #d7d590;
        padding: 12px 8px;
        margin: 5px;
        background-color: cornsilk;
        box-shadow: 1px 1px 1px rgb(225 216 130);
        display: inline-block;
        font-size: 14px;
        font-family: "Fredoka", sans-serif;
        text-transform: uppercase;
        cursor: pointer;
    }
    
    .AdminCourses-instructor:hover {      
        background-color: #f7f5b4;     
    }
</style>