<script>
import List from '@/components/List/List.vue'
import Form from '@/components/Form/Form.vue'
import PopUp from '@/components/PopUp.vue'
import ToAdmin from '@/components/ToAdmin.vue'
import message from '@/helpers/message'
import UserInstruction from '@/components/UserInstruction.vue'
export default {
    components:{ List, Form, PopUp, ToAdmin, UserInstruction },
    created() {  
        if(this.user.user_level != 'SamfallifyCrew' && this.user.user_level != 'Redaktör')
        {
            this.$router.push('/login');
        } 
       
        this.getEmailLog();
    },
    data() {
        return {  
            emailLog: null, 
            columns: [                                          
                { name: 'recipient_name', displayName: 'Skickat till', width: 250, hideFilter: false},                             
                { name: 'subject', displayName: 'Rubrik', width: 300, hideFilter: true},               
                { name: 'text', displayName: 'Text', isTextEditorFormat: true, width: 700, hideFilter: true}, 
                { name: 'recipient_type', displayName: 'Typ av mottagare', width: 200, hideFilter: false},                             
                { name: 'crated_date_formatted', displayName: 'Skickad', width: 200, hideFilter: true},
                { name: 'created_by_name', displayName: 'Skickad Av', width: 200},
              
            ], 
            sorting: [
                { name: 'recipient_name', displayName: 'Namn' },           
                { name: 'recipient_type', displayName: 'Typ av mottagare' },           
                { name: 'created_date', displayName: 'Skapad' },           
                { name: 'created_by_name', displayName: 'Skapad Av' },           
            ],
            editItem: null,
            form: [                   
                    { sections: [
                            { sectionName: 'Vem/Vilka vill du skicka till', classes: "g-100", inputs: [
                                { type: 'multiSelectUsers', prop: 'recipient_ids', typeProp: 'recipient_type', title: 'Välj vem/vilka som ska få emailet', classes: "g-100" },  
                                ]
                            },
                            { sectionName: 'Emailets innehåll', classes: "g-100", inputs: [ 
                                { type: 'text', prop: 'subject', title: 'Rubrik', classes: "g-100", required: true },
                                { type: 'textEditor', prop: 'text', title: 'Text', classes: "g-100", required: true },
                                ]
                            }                        
                        ]
                    }                  
            ],
        }
    },   
    methods:
    {
        edit(item)
        {
            this.editItem = item;
        },
        add()
        {
            this.editItem = { id: 0, subject: '', text: '', recipient_ids: [], recipient_type: 'Specifika användare' };
        },      
        getEmailLog()
        {
            var self = this;
            this.$communicator.get('email-log/')
                .then(function(data)
                {                 
                    self.emailLog = data;
                    self.editItem = null;
                  
                })
                .fail(function(error) {                  
                })  
        }, 
    },
    computed:
    {
        user() {return this.$store.state.user; }          
    }
}
</script>
<template>
    <div v-fade-in v-if="emailLog != null">
        <main class="opacity-admin-bg overflow-auto position-relative">
            <div class="float-right">
                <button id="List-sort-btn" class="btn" @click="add">Gör ett emailutskick</button>
                <UserInstruction :page="this.$route.name"></UserInstruction>
            </div>
            <ToAdmin :section="'Emailutskick'"></ToAdmin>
            <h2>Emailutskick</h2>          
                <div v-if="editItem != null">
                    <PopUp>
                        <h2>Gör ett Emailutskick</h2>
                        <Form :form="form" :item="editItem" :url="'send-email/'"  :saveText="'Skicka'" :getItemFunction="getEmailLog" :inEditMode="true"></Form>
                    </PopUp>
                </div>
            <List v-if="emailLog.length > 0" :items="emailLog" :columns="columns" :successMessage="'Härligt! Emailet har skickats ut till valda mottagare!'" :noOfFrozenColumns="2" :isEditing="editItem != null" :nameOfItems="'st'" :sorting="sorting" :backgroundColor="this.$colors.list.backgroundColor" :borderColor="this.$colors.list.borderColor"></List>     
            <div v-else>
                <p>Du har inte gjort några utskick än så länge.</p>
            </div>
        </main>
    </div>
</template>
<style scoped>
    .AdminNotifications-edit-panel
    {
        padding:10px;
        margin: 15px 0px;
        border:1px solid #ddd;
    }
</style>