<script>
import List from '@/components/List/List.vue'
import Form from '@/components/Form/Form.vue'
import PopUp from '@/components/PopUp.vue'
import ToAdmin from '@/components/ToAdmin.vue'
import message from '@/helpers/message'
import UserInstruction from '@/components/UserInstruction.vue'
export default {
    components:{ List, Form, PopUp, ToAdmin, UserInstruction },
    created() {  
        if(this.user.user_level != 'SamfallifyCrew' && this.user.user_level != 'Redaktör')
        {
            this.$router.push('/login');
        } 
        this.$cache.createCache('boardMembers', 'board-members/');
    },
    data() {
        return {   
            columns: [                             
                { name: 'edit', displayName: '<i class="fa-solid fa-pen color-black"></i>',  title: 'Redigera', icon: 'fa-solid fa-pencil', align:'center', function: this.edit, width: 25, hideFilter: true, hideIfEdit: true},
                { name: 'delete', displayName: '<i class="fa-solid fa-trash color-black"></i>', title: 'Ta bort', isDelete: true, align:'center', width: 25, hideFilter: true, hideIfEdit: true},
                { name: 'send', displayName: '<i class="fa-solid fa-message color-black"></i>', title: 'Skicka meddelande', isSendUserMessage: true, align: 'center', width: 30, hideFilter: true, hideIfEdit: true},         
                { name: 'board_member_type', displayName: 'Titel', width: 160, hideFilter: false},
                { name: 'name', displayName: 'Namn', width: 200, hideFilter: false},
                { name: 'period', displayName: 'Vald Period', width: 300, hideFilter: true},
                { name: 'created_date', displayName: 'Skapad', width: 100, hideFilter: true},
                { name: 'created_by', displayName: 'Skapad Av', width: 200},
              
            ], 
            sorting: [
                { name: 'board_member_type', displayName: 'Titel' },           
                { name: 'name', displayName: 'Namn' },           
                { name: 'created_date', displayName: 'Skapad' },           
                { name: 'created_by', displayName: 'Skapad Av' },           
            ],
            editItem: null,
            form: [                   
                    { sections: [{ sectionName: 'Medlem och titel', classes: "g-100", inputs: [ 
                             { type: 'select', prop: 'board_member_type', title: 'Titel', classes: "g-50", required: true },
                             { type: 'select', prop: 'user_id', title: 'Medlem', classes: "g-50", required: true, selectItems: 'users' },
                            ]
                        }]
                    },
                    { sections: [{ sectionName: 'Vald Period', classes: "g-100", inputs: [
                            { type: 'date', prop: 'elected_start_date', title: 'Startdatum', classes: "g-50", required: true }, 
                            { type: 'date', prop: 'elected_end_date', title: 'Slutdatum', classes: "g-50", required: true }, 
                            ]
                        }]
                }],
        }
    },   
    methods:
    {
        edit(item)
        {
            this.editItem = item;
        },
        add()
        {
            this.editItem = { id: 0, board_member_type: null, elected_end_date: null, elected_start_date: null };
        },
        delete(id)
        {
            var self = this;
            this.$communicator.post('board-members/' + id + '/delete')
                .then(function(data)
                {                 
                    self.getBoardMembers();
                    message.setSuccessMessage();
                })
                .fail(function(error) {                  
                })  
        },
        getBoardMembers()
        {
            this.editItem = null;
            this.$cache.updateCache('boardMembers', 'board-members/');
        }
    },
    computed:
    {
        user() {return this.$store.state.user; },
        boardMembers() {  
            return this.$store.state.boardMembers != null ? this.$store.state.boardMembers.map(x => (
                {
                    id: x.id, 
                    created_date: x.formatted_created_date, 
                    board_member_type: x.board_member_type,
                    name: x.user.full_name,                 
                    first_name: x.user.first_name,                
                    last_name: x.user.last_name,                
                    created_by: x.created_by_user.full_name,
                    user_id: x.user_id,
                    elected_end_date: x.elected_end_date,
                    elected_start_date: x.elected_start_date,
                    period: x.formatted_elected_start_date + ' - ' + x.formatted_elected_end_date
                })) : []
            }       
    }
}
</script>
<template>
    <div v-fade-in>
        <main class="opacity-admin-bg overflow-auto position-relative">
            <div class="float-right">
                <button id="List-sort-btn" class="btn" @click="add">Lägg till en styrelsemedlem</button>
                <UserInstruction :page="this.$route.name"></UserInstruction>
            </div>
            <ToAdmin :section="'Styrelsen'"></ToAdmin>            
            <h2>Styrelsen</h2>         
            <div v-if="editItem != null">
                <PopUp>
                <Form :form="form" :item="editItem" :url="'board-members/'" :getItemFunction="getBoardMembers" :inEditMode="true"></Form>
                </PopUp>
            </div>
            <List :items="boardMembers" :columns="columns" :noOfFrozenColumns="4" :deleteFunction="delete" :isEditing="editItem != null" :nameOfItems="'styrelsemedlemmar'" :sorting="sorting" :backgroundColor="this.$colors.list.backgroundColor" :borderColor="this.$colors.list.borderColor"></List> 
            <div v-if="boardMembers.length == 0">
                Klicka på 'Lägg till en styrelsemedlem' för att börja skapa din styrelse.
            </div>       
        </main>
    </div>
</template>
<style scoped>
    .AdminNotifications-edit-panel
    {
        padding:10px;
        margin: 15px 0px;
        border:1px solid #ddd;
    }
</style>