<script>
import Form from '@/components/Form/Form.vue'
import YesNoPopUp from '@/components/YesNoPopUp.vue';
import PopUp from '@/components/PopUp.vue'
import message from '@/helpers/message'
import config from "@/helpers/config"

export default {
    components:{ Form, PopUp, YesNoPopUp },
    props: ['instructors', 'getCourses'],
    data() {
        return {             
           instructorItem: null,
           deleteItem: null,
           isAdding: false          
        }
    },   
    methods:
    {
        edit(item)
        {
            this.isAdding = false;
            this.instructorItem = item;
        },
        add()
        {
            this.isAdding = true;
            this.instructorItem = { id: 0, user_id: null, description: null, image_id: null }       
        },  
        remove()
        {
            var self = this;
            this.$communicator.post('admin/courses/instructors/' + this.deleteItem.id + '/delete')
            .then(function(data)
            {     
                self.deleteItem = null;            
                self.getCourses();
                message.setSuccessMessage();
            })
            .fail(function(error) {                  
            })  
        },
        reloadCourses(){
            this.instructorItem = null;
            this.getCourses();
        }
        
    },
    computed:
    {
        serverUrl() { return config.urlToServer } ,
        settings() {return this.$store.state.settings; },
        form() { return [                   
            { sections: [
                { sectionName: 'Välj användare och bild', classes: "g-50", inputs: [
                    { type: 'select', prop: 'user_id', title: 'Välj användare', selectItems: 'users', classes: "g-100", required: true }, 
                    { type: 'image', prop: 'image_id', title: 'Välj bild', classes: "g-100"}, 
                    
                    ]
                },
                { sectionName: 'Beskrivning', classes: "g-50", inputs: [
                    { type: 'textEditor', prop: 'description', title: 'Beskrivning av ' + this.settings.CourseInstructor.singular , classes: "g-100", required: false, height: '400px' }, 
                    
                    ]
                }]
            }]
        }
    }
  
}
</script>
<template>
    <button class="btn float-right" style="margin-top: 7px;" @click="add">Lägg till {{settings.CourseInstructor.singular }}</button>
    <h2>{{settings.CourseInstructor.plural }}</h2>
    <div class="instructor-area">
        <div v-for="instructor in instructors" class="AdminCourses-instructor" @click="edit(instructor)">
            <img v-if="instructor.image_name" :src="serverUrl + 'uploads/'+ instructor.customer_id +'/images/'+ instructor.image_name"  style="max-height: 118px"  v-tooltip="'Klicka för att redigera'"  />  
            <i v-else class="fa-solid fa-user-graduate"  v-tooltip="'Klicka för att redigera'" ></i>
            <div class="margin-top-15">{{ instructor.name }} <span class="fa-solid fa-trash" @click.stop="deleteItem = instructor" v-tooltip="'Klicka för att ta bort'"></span></div>
        </div>
    </div>

    <PopUp v-if="instructorItem">       
       <h2>{{ isAdding ? 'Lägg till' :'Redigera' }} {{settings.CourseInstructor.singular }}</h2>
       <Form :form="form" :item="instructorItem" :url="'admin/courses/instructors/'" :getItemFunction="reloadCourses" :inEditMode="true" :successMessage="instructors.length == 0 ? 'Härligt, den första instruktören är inlagd och du har nu möjligheten att börja lägga till kurser! :)' : 'Härligt! Instruktören är nu tillagd'"></Form>
    </PopUp>
    <div v-if="deleteItem != null">
        <YesNoPopUp :yes="remove" :no="() => deleteItem = null" :text="'Är du säker på att du vill ta bort?'"></YesNoPopUp>       
    </div>
</template>
<style scoped>
    .instuctor-and-location-aside {
        width: calc(25% - 87px);
        margin-right: -34px;
        background-color: rgba(250, 233, 197, 0.98);
        z-index: 1;
        margin-top: 99px;
        margin-bottom: 8px;
    }

    .instructor-area{
        min-height: 300px; 
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
    }

    .AdminCourses-instructor i {
        font-size: 5vw;
        color: rgb(225 216 130);
    }
    
    .AdminCourses-instructor {
        border-radius: 5px;
        width: calc(50% - 28px);
        text-align: center;
        border: 1px solid #d7d590;
        padding: 12px 8px;
        margin: 5px;
        background-color: cornsilk;
        box-shadow: 1px 1px 1px rgb(225 216 130);
        font-size: 14px;
        font-family: "Fredoka", sans-serif;
        text-transform: uppercase;
        cursor: pointer;
    }
    
    .AdminCourses-instructor:hover {      
        background-color: #f7f5b4;     
    }
</style>