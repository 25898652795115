<script>
import Switch from '@/components/Form/Switch.vue'
import ToAdmin from '@/components/ToAdmin.vue'
import PopUp from '@/components/PopUp.vue'
import Form from '@/components/Form/Form.vue'
import message from '@/helpers/message'
export default {
    components: { Switch, ToAdmin, PopUp, Form },
    created() {  
        if(this.user.user_level != 'SamfallifyCrew' && this.user.user_level != 'Redaktör')
        {
            this.$router.push('/login');           
        }   

        this.getAreas();
    },
    data() {
        return {          
            editItem: null,
            areas: null,
            enables: null,
            singulars: null  
        }
    },   
    methods:
    {
        save() {
            var self = this;
            this.$communicator.post('admin-settings/', this.areas)
                .then(function(data)
                {                                    
                    message.setSuccessMessage();  
                    self.getSettings();             
                    
                })
                .fail(function(error) {    
                                  
                }) 
        },
        removeWords() {
            this.editItem.singular = null;
            this.editItem.plural = null;
            this.editItem = null;
        },
        getAreas() {
            this.editItem = null;
            var self = this;
            this.$communicator.get('admin-settings/')
                .then(function(data)
                {    
                    var adminSettings = {};
                    for(var i = 0; i < data.length; i++)
                    {
                        adminSettings[data[i].name] = data[i]
                    } 
                    self.areas = [
                        {hasEnable: false, default_singular: self.$store.state.settings.News.default_singular, isEditing: false, singular: adminSettings.News?.singular, class_name: 'News', enabled: true, name: 'Aktuellt', icon: 'fa-solid fa-paper-plane', text: 'Aktuella meddelanden är en jättebra funktion för att snabbt nå ut till alla medlemmar samt till de som besöker den publika sidan. Du kan inte inaktivera aktuellt men du kan byta namnet.' },
                        {hasEnable: true, default_singular: self.$store.state.settings.BoardMember.default_singular, isEditing: false, singular: adminSettings.BoardMember?.singular, class_name: 'BoardMember', enabled: self.$store.state.settings.BoardMember.enabled, name: 'Styrelsen', icon: 'fa-solid fa-people-line', text: 'Att ha en styrelse är bra om du har den typen av förening där styrelsearbetet är viktigt, till exempel bostadsrättsföreningar eller samfälligheter.' },
                        {hasEnable: false, default_singular: self.$store.state.settings.User.default_singular, isEditing: false, singular: adminSettings.User?.singular, plural: adminSettings.User?.plural, class_name: 'User', enabled: true, name: 'Medlemmar', icon: 'fa-solid fa-people-roof', text: 'Medlemsregistret är ett måste för att appen ska kunna fungera. Detta går inte att inaktivera men du kan byta namn från "medlem" till något som mer passar er verksamhet.' },
                        {hasEnable: true, default_singular: self.$store.state.settings.WorkGroup.default_singular, isEditing: false, singular: adminSettings.WorkGroup?.singular, plural: adminSettings.WorkGroup?.plural, class_name: 'WorkGroup',  enabled: self.$store.state.settings.WorkGroup.enabled, name: 'Arbetsgrupper', icon: 'fa-solid fa-people-roof', text: 'Arbetsgrupper är en jättebra funktion om er förening består av många mindre grupper eller avdelningar. Varje avdelning får en egen arbetsyta där de tex kan chatta med varandra och ladda upp filer' },
                        {hasEnable: true, default_singular: self.$store.state.settings.Forum.default_singular, isEditing: false, singular: adminSettings.Forum?.singular, class_name: 'Forum',  enabled: self.$store.state.settings.Forum.enabled, name: 'Forumet', icon: 'fa-solid fa-comments', text: 'Forumet är ett ypperligt mötesrum för föreningens medlemmar. Ni sätter själva upp vilka avdelningar som ska finnas i forumet. Genom forumet kan man även komma i kontakt med styrelse eller med arrbetsgrupperna om den funktionen är påslagen' },
                        {hasEnable: true, default_singular: self.$store.state.settings.BookingItem.default_singular, isEditing: false, singular: adminSettings.BookingItem?.singular, plural: adminSettings.BookingItem?.plural, class_name: 'BookingItem', enabled: self.$store.state.settings.BookingItem.enabled, name: 'Bokningar', icon: 'fa-solid fa-calendar-days', text: 'Hantera vad som går att hyra/låna inom din förening. Perfekt för er som har en bastu, en tvättstuga, en gästlägenhet eller festlokal i föreningen. ' },
                        {hasEnable: true, default_singular: self.$store.state.settings.Course.default_singular, isEditing: false, singular: adminSettings.Course?.singular, plural: adminSettings.Course?.plural, class_name: 'Course', enabled: self.$store.state.settings.Course.enabled, name: 'Kurser', icon: 'fa-solid fa-user-graduate', text: 'Håller din förening i utbildningar och kurser? Då är kursmodulen här för att stanna. Skapa kurser, ha koll på antal anmälda och nå ut genom den publika sidan.' },
                        {hasEnable: true, default_singular: self.$store.state.settings.Invitation.default_singular, isEditing: false, singular: adminSettings.Invitation?.singular, plural: adminSettings.Invitation?.plural,  class_name: 'Invitation', enabled: self.$store.state.settings.Invitation.enabled, name: 'Inbjudan', icon: 'fa-solid fa-handshake-simple', text: 'Skapa inbjudan till årsmöten, städdagar, trivselaktiviter, fester eller events. Denna funktion passar alla typer av föreningar.' },
                        {hasEnable: true, default_singular: self.$store.state.settings.Statute.default_singular, isEditing: false, singular: adminSettings.Statute?.singular, plural: adminSettings.Statute?.plural,  class_name: 'Statute', enabled: self.$store.state.settings.Statute.enabled, name: 'Stadgar', icon: 'fa-solid fa-gavel', text: 'Här kan du lägga upp föreningens stadgar.' },
                        {hasEnable: true, default_singular: self.$store.state.settings.RuleOfProcedure.default_singular, isEditing: false, singular: adminSettings.RuleOfProcedure?.singular, plural: adminSettings.RuleOfProcedure?.plural,  class_name: 'RuleOfProcedure', enabled: self.$store.state.settings.RuleOfProcedure.enabled, name: 'Ordningsregler', icon: 'fa-solid fa-person-harassing', text: 'Här kan du lägga upp föreningens ordningsregler.' },        
                        {hasEnable: false, default_singular: self.$store.state.settings.Community.default_singular, isEditing: false, singular: adminSettings.Community?.singular, class_name: 'Community', enabled: self.$store.state.settings.Community.enabled, name: 'Föreningen', icon: 'fa-solid fa-users', text: 'Här kan du byta namn på menyvalet \'Föreningen\' som användaren klickar på för att se styrelse, medlemmar, stadgar etc.' },        
                        {hasEnable: false, default_singular: self.$store.state.settings.ImageGallery.default_singular, isEditing: false, singular: adminSettings.ImageGallery?.singular, class_name: 'ImageGallery', enabled: self.$store.state.settings.ImageGallery.enabled, name: 'Bildgalleriet', icon: 'fa-solid fa-users', text: 'Här kan du byta namn på bildgalleriet om du tycker att det är lite stelt och tråkigt.' },        
                    ]                               
                             
                    self.enables = self.areas.map(x => x.enabled);
                    self.singulars = self.areas.map(x => x.singular);
                    
                })
                .fail(function(error) {    
                                  
                }) 
        },
        async getSettings()
        {
            const settings = await this.$communicator.get("settings/");
            this.$store.commit('setEntity', { entityName: 'settings', entity: settings })
            this.getAreas();
        }, 
        closePopup() {
            this.getAreas();
        }
    },
    computed:
    {
        user()
        {
            return this.$store.state.user;
        },
        hasChanges() {
            if(this.areas == null || this.enables == null || this.singulars == null) { return false}
            return JSON.stringify(this.enables) !== JSON.stringify(this.areas.map(x => x.enabled)) ||
                JSON.stringify(this.singulars) !== JSON.stringify(this.areas.map(x => x.singular))
        }
    }
}
</script>
<template>
    <div v-fade-in>
        <main class="opacity-admin-bg overflow-auto position-relative">
            <ToAdmin :section="'Appens funktioner'"></ToAdmin>
            <h2>Appens funktioner</h2>
            <div>Jamii kommer fullmatad med funktionalitet och finesser. Allt det som jamii har att erbjuda kanske inte passar er förening men som tur är går detta att själv bestämma vad man vill ha och även vad mycket i appen ska heta.
                <br>Viktigt att komma ihåg: Om man inaktiverar en funktion som man redan har använt så kommer inte informationen att försvinna. Så väljer du att inaktivera till exempel forumet och senare aktiverar den, så kommer forumet se ut precis som det gjorde innan du inaktiverade det.</div>  
            <div class=" margin-top-15 Admin-area-wrapper">
                <div v-for="area in areas" class="Admin-area">             
                    <div class="text-center" :class="[area.enabled ? 'Admin-opacity-1':'Admin-opacity-03']" @click="area.isEditing = true">
                        <i :class=area.icon></i>
                        <h3 class="position-relative"> <span v-if="area.singular" style="font-style: italic" v-tooltip="'Detta namnet har du själv bytt till, klicka på pennan och välj \'Återställ till grundinställningen\' om du har ångrat dig.'">{{area.plural ? area.plural : area.singular }}</span>
                        <span v-else>{{ area.name }}</span><i v-if="area.enabled" @click="editItem = area" class="fa-solid fa-pencil" style="font-size: 16px; position: absolute; left: -20px; top: 2px; cursor:pointer;" v-tooltip="'Anpassa namnet'"></i></h3>
                    </div>
                    <div>
                        <p :class="[area.enabled ? 'Admin-opacity-1':'Admin-opacity-03']"> {{ area.text }}</p>  
                    </div>
                    <div>
                        <Switch  v-if="area.hasEnable" :model="area" :prop="'enabled'" v-tooltip="'Aktivera/Inaktivera ' + area.name.toLowerCase()"></Switch>                       
                    </div>                                     
                </div>
            </div>    
        </main>
    </div>
    <div v-if="editItem != null">
        <PopUp class="AdminSettings-PopUp" :closePopup="closePopup">
            <h2>Anpassa {{ editItem.name }}</h2>
            <div>
                <p>Vad vill du kalla '{{ editItem.default_singular }}' för?</p>
                <input type="text" v-model="editItem.singular" />          
            </div>
            <div v-if="Object.hasOwn(editItem, 'plural')">
                <p>I detta fallet vill vi även att du matar in pluralformen av ordet eftersom det används på båda sätten, tex. medlem och medlemmar.</p>
                <input type="text" v-model="editItem.plural" :placeholder="editItem.singular ? 'skriv in pluralformen av ' + editItem.singular : ''" />
            </div>
            <div class="text-right">
                <button class="btn btn-gray" @click="getAreas">Avbryt</button>
                <button class="btn btn-gray" @click="removeWords" v-if="editItem.singular != null">Återställ till grundinställningen</button>
                <button class="btn" @click="editItem = null">Klar</button>
            </div>
        </PopUp>
    </div>
    <button class="btn Admin-save-btn" v-if="hasChanges" @click="save" v-fade-in>Spara ändringarna</button>
</template>
<style scoped>
.AdminSettings-PopUp
{
    text-align: left;
}

.AdminSettings-PopUp input
{
    width: calc(100% - 36px);
    font-size: 17px;
    padding: 15px;
}

.AdminSettings-PopUp div
{
    margin-bottom: 10px;
}

.Admin-area
{
  padding:28px 20px;
  overflow: auto;
  width: calc(33% - 42px);
  border: 1px solid  rgba(250, 233, 197, 0.95);
  display: flex;
  justify-content: space-between;
  align-content: flex-start;
}

.Admin-area .fa-sun {
    color: yellow;
    position: absolute;
    top: -10px;
    left: -13px;
    display: block;
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
}

.Admin-area-wrapper
{
    display: flex; 
    flex-wrap: wrap;
}

.Admin-area input
{
    margin-top: 15px;
    font-size: 1.1em;
    font-weight: bold;
    text-align: center;
    background-color: rgba(250, 233, 197, 0.95) !important;
    width: 150px;
}

.Admin-opacity-1
{
    opacity: 1;
    transition: ease 1s;
}

.Admin-opacity-03
{
    opacity: 0.3;
    transition: ease 1s;
}

.Admin-area:hover
{
  border: 1px solid #4c6675;
}

.Admin-area i
{
 font-size: 30pt;
}

.Admin-area p
{
    font-size: 13px;
    color: black;
    margin-left: 17px;
}

.Admin-save-btn
{
    padding: 15px 0px;
    background-color: #58d64d !important;
    color: white;
    position:fixed;
    bottom: 0px;
    right: 0px;
    left: 0px;
    width: 100%;
    font-size: 16px;
}

@media only screen and (max-width: 1500px) {
    .Admin-area p
    {
    margin: 0px;
    font-size: 14px;
    line-height: 20px;
    }

    .Admin-area h3 {
    margin: 14px 0px 8px 0px;
    }

    .Admin-area
    {
        min-height: 190px;
    }
}
</style>