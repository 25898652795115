<script>
    import MultiSelect from '@/components/Form/MultiSelect.vue'
    export default {
        props: ['model', 'prop', 'typeProp'],
        components: {MultiSelect },
        data() {
            return {
                selectedWorkGroupIds: [],
                selectedWorkGroupMembers: [],
                selectedType: [],
                admins: [],
                availableTypes: [
                    { name: 'admins', display_name: 'Alla redaktörer', selected: false}, 
                    { name: 'allUsers', display_name: 'Samtliga ' + this.$store.state.settings.User.plural, selected: false },
                    { name: 'board', display_name: this.$store.state.settings.BoardMember.singular, selected: false }, 
                    { name: 'workGroups', display_name: this.$store.state.settings.WorkGroup.plural, selected: false }, 
                    { name: 'specificUsers', display_name: 'Specifika ' + this.$store.state.settings.User.plural, selected: false }
                ],
            }
        },
        methods: {
            selectType(type) {
                this.availableTypes.forEach(x => {
                    x.selected = false;
                });
                this.model[this.typeProp] = type.display_name;
                this.selectedType = type.name;
                this.selectedWorkGroupIds = [];
                type.selected = true;

                switch(type.name)
                {
                    case 'allUsers':
                        this.model[this.prop] = this.users.map(x => x.id)
                        break;
                    case 'board':
                        this.model[this.prop] = this.boardMembers.map(x => x.user.id)
                        break;
                    case 'specificUsers':
                    case 'workGroups':
                        this.model[this.prop] = [];  
                        break;
                    case 'admins':
                        this.model[this.prop] = this.admins.map(x => x.id);  
                        break;            
                }
            }          
        },
        created() {
            this.$cache.createCache('workGroups', 'work-groups/');

            var self = this;
            this.isAdding = false;
            this.$communicator.get('admins/')
            .then(function(data)
            {             
               self.admins = data;
            })
            .fail(function(error) {
                
            });  
     
        },
        computed:
        {
            users() { return this.$store.state.users != null ? this.$store.state.users : null },
            boardMembers() { return this.$store.state.boardMembers != null ? this.$store.state.boardMembers : [] },           
            workGroups() { return this.$store.state.workGroups != null ? this.$store.state.workGroups : [] },           
        },
        watch:
        {
            selectedWorkGroupIds(newvalue)
            {
                if(newvalue.length > 0)
                {
                    var userIds = [];
                    var userNames = [];
                    newvalue.forEach(x => {
                        var workGroup = this.workGroups.find(wg => wg.id == x);                   
                        workGroup.members.forEach(m => {
                            userIds.push(m.user_id);
                            userNames.push(m.user.full_name);
                            
                        })
                    });
                    this.model[this.prop] = [...new Set(userIds)];
                    this.selectedWorkGroupMembers = [...new Set(userNames)];
                }
            }
        }
    }
</script>

<template>
    <div class="row">
        <div class="g-100">
            <button class="btn" v-for="type in availableTypes" :class="type.selected ? '' : 'btn-gray'" @click="selectType(type)">{{ type.display_name }}</button>
        </div>
        <div v-if="selectedType == 'allUsers'" class="margin-top-15">
            <b>Mottagare:</b>
            <div v-for="user in users">
                {{ user.full_name }} 
            </div>
        </div>
        <div v-else-if="selectedType == 'board'" class="margin-top-15">
            <b>Mottagare:</b>
            <div v-for="member in boardMembers">
                {{ member.user.full_name }}
            </div>
        </div>
        <div v-else-if="selectedType == 'admins'" class="margin-top-15">
            <b>Mottagare:</b>
            {{ admins.map(x => x.full_name).join(', ') }}
        </div>
        <div v-else-if="selectedType == 'specificUsers'">
            <label> Välj specifika {{ this.$store.state.settings.User.plural }} *</label>
            <MultiSelect v-model:selectedItemSysIDs="model[prop]" :selectItems="'users'"></MultiSelect>
        </div>
        <div v-else-if="selectedType == 'workGroups'">
            <label> Välj {{ this.$store.state.settings.WorkGroup.singular }}/{{ this.$store.state.settings.WorkGroup.plural}} *</label>
            <MultiSelect v-model:selectedItemSysIDs="selectedWorkGroupIds" :selectItems="'workGroups'"></MultiSelect>
            Mottagare av e-mailet: <b>{{ selectedWorkGroupMembers.length == 0 ? 'Välj någon ' + this.$store.state.settings.WorkGroup.singular : selectedWorkGroupMembers.join(", ") }}</b>
        </div>
    </div>
</template>
    
<style scoped>
label{
        text-align: left;
        margin: 15px 0px -7px 0px;
        display: block;
    }

</style>