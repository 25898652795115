<script>
import Incoming from '../components/Incoming.vue'
import AdminLink from '../components/AdminLink.vue'
import UserIntro from '../components/UserIntro.vue'
import config from "@/helpers/config"
export default {
    components: { Incoming, AdminLink, UserIntro },
    created() {  
        this.$cache.createCache('news', 'news/');
        this.getUserSettings();
    },
    data() {
        return { 
            user: null    
        }
    },
    methods:
    {
        getUserSettings()
        {
            var self = this;   
            this.$communicator.get('user-settings/')
            .then(function(data)
            {                
                self.user = data;
            })
            .fail(function(error) {
                self.$apiErrorhandler.handleErrors(error, self.$route.fullPath)              
            }); 
        },
        
        // sendMessage(message) {
        // if (this.socket && this.socket.readyState === WebSocket.OPEN) {
        //     this.socket.send(message);
        // }
        // }
    },
    computed:
    {
        news() {  return this.$store.state.news == null ? [] : this.$store.state.news; },
        serverUrl() { return config.urlToServer } 
    }
}
</script>
<template>
    <main class="template-two-cols-right-20-percent" v-fade-in>
        <div class="Home-sections">
            <section style="margin-bottom: 15px;" v-if="user != null && !user.hide_intro && 1 > 2">
                <UserIntro :user="user"></UserIntro>
            </section>           
            <section>
                
                <AdminLink :buttonText="'Lägg till/ändra ett meddelande'" :sectionToEdit="'adminNews'"></AdminLink>
                <h2>{{ this.$store.state.settings.News.singular }}</h2>           
                <div>
                    <div v-for="newsItem in news" class="Home-notification-item">   
                        <div v-html="newsItem.message"></div>
                        <div v-for="image in newsItem.images">
                            <img :src="serverUrl + 'uploads/'+ image.customer_id+'/images/'+ image.name"  style="width: 100%" />  
                        </div>              
                        <div style="margin-top:10px;">
                            <i>/{{ newsItem.created_by_user.first_name }}
                                {{ newsItem.created_by_user.last_name }},  
                                <span v-date="{ date: newsItem.created_date, type: 'dateInLetters' }"></span>  
                            </i>
                        </div>    
                    </div>
                    <div v-if="news.length == 0">
                        Här var det tomt.</div>
                    </div>
                <br>
            
            </section>
        </div>
        <aside id="incoming-events" style="float: right; background-color: hsla(41, 70%, 98%, 0.99);">
            <Incoming></Incoming>
        </aside>
</main>
</template>
<style scoped>
    h4{
        margin-bottom: 7px;
    }

    .Home-notification-item
    {
        padding:15px;
        margin: 5px 30px;
        border-radius: 4px;
        margin-bottom: 10px;
        display: inline-block;
        width: calc(50% - 90px);
        vertical-align: top;
        border-top: 1px solid #ddd;
    }

    .Home-notification-item i{
        font-size: 14px;
    }

    .Home-sections
    {
        float:left;
        width:calc(80% - 40px);
        margin-right: 10px;
    }

    section{
        float:none !important;
        width:100% !important;
    }

    @media only screen and (max-width: 1000px) {
        .Home-sections
        {
            width:calc(100% - 50px);
        }

        .Home-notification-item
        {        
            width: calc(100% - 40px);
            padding:15px;
            margin: 5px;
        }
    }
</style>