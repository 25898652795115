<script>
import PopUp from '@/components/PopUp.vue'
import message from '@/helpers/message'
export default {
    components: { PopUp },
    data(){
        return {
            month: '',
            currentDate: null,
            noOfDays: null,
            today: new Date(),
            months: this.$isMobile ? 
                ['jan', 'feb', 'mars', 'april', 'maj', 'juni', 'juli', 'aug','sept','okt', 'nov', 'dec']
                : ['januari', 'februari', 'mars', 'april', 'maj', 'juni', 'juli', 'augusti','september','oktober', 'november', 'december'],
            days: ['Mån', 'Tis', 'Ons', 'Tors', 'Fre', 'Lör', 'Sön'],
            daysInNumbers: [6,0,1,2,3,4,5],
            allDaysInMonth: [] ,
            allBookingTimes: null,           
            bookingItem: null,
            hours: [],
            bookedTimes: [],
            blockings: [],
            selectedDay: null,
            newBookingTime: { 
                id: 0, 
                selected_date: null, 
                start_time: null, 
                end_time: null, 
                start_date: null, 
                end_date: null, 
                comments: '', 
                booking_item_id: null,
                duration_time: null,
                duration_unit: null
             },
            indexOfStart: null,
            indexOfEnd: null,
            showSummary: false
        }
    },
    created()
    {
        var self = this;     
        this.currentDate = new Date();
        this.$communicator.get('add-booking/' + this.$route.params.id)
        .then(function(data)
        {             
            self.bookingItem = data.booking;
            self.allBookingTimes = data.allBookings;
            self.blockings = data.blockings;
            self.renderCalenderMonth(self.currentDate);
        })
        .fail(function(error) {
            self.$apiErrorhandler.handleErrors(error, self.$route.fullPath)  
        });   
    },
    methods:
    {
        showPrevMonth()
        {
            this.emptyBookingValues();
            this.currentDate.setMonth(this.currentDate.getMonth()-1);
            this.renderCalenderMonth(this.currentDate);
        },
        showNextMonth()
        {
            this.emptyBookingValues();
            this.currentDate.setMonth(this.currentDate.getMonth()+1);
            this.renderCalenderMonth(this.currentDate);
        },
        renderCalenderMonth(date)
        {
            this.month = this.months[date.getMonth()];
            this.noOfDays = new Date(date.getFullYear(), date.getMonth()+1, 0).getDate();         
            var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);            
            var dayInMonth = 1;
            var dayInNextMonth = 1;
            
            //fill calender with days
            this.allDaysInMonth = [];
            for(var i = 0; i <= 41; i++)
            {
                if(i >= this.daysInNumbers[firstDay.getDay()] && dayInMonth <= this.noOfDays)
                {
                   //calculate day date and if its sunday and if its today
                    var dayDate = new Date(date.getFullYear(), date.getMonth(), dayInMonth);
                    
                    var isToday = this.today.getFullYear() == dayDate.getFullYear() && this.today.getMonth() == dayDate.getMonth() && this.today.getDate() == dayDate.getDate();
                    this.allDaysInMonth.push({ date: dayDate, dateString: this.getDateString(dayDate), day: dayInMonth++, isCurrentMonth: true, isSunday: dayDate.getDay() == 0, isToday: isToday });
                }
                else if(i < this.daysInNumbers[firstDay.getDay()])
                {
                    this.allDaysInMonth.push({day: 'prevMonth', isCurrentMonth: false, isSunday: false, isToday: false });
                    
                } else{
                    this.allDaysInMonth.push({ day: dayInNextMonth++, isCurrentMonth: false, isSunday: false, isToday: false });                    
                }
            }

            //fill previous months visible days
            var noOfDaysPrevMonth = new Date(date.getFullYear(), date.getMonth(), 0).getDate();
            for(var i = 41; i >= 0; i--)
            {
                if(this.allDaysInMonth[i].day  == 'prevMonth')
                {
                    this.allDaysInMonth[i].day = noOfDaysPrevMonth--;
                }
            }      
        },
        getDateString(date)
        {
            return date.getFullYear() + '-' + ("0" + (date.getMonth() + 1)).slice(-2) + '-' + ("0" + date.getDate()).slice(-2);
        },
        selectDate(date)
        {  
            if(!date.isCurrentMonth)
            {
                return;
            }  

            this.selectedDay = date;

            //highlight day in calendar    
            this.allDaysInMonth.forEach(function(date) {
                date.selected = false;
            });                   
            date.selected = true;

            this.newBookingTime.selected_date = date.dateString;
            this.emptyBookingValues();

            this.bookedTimes = this.allBookingTimes.filter(x => date.date != undefined && x.start_date.split('T')[0] == date.dateString);
            var occupiedHours = [];
            this.bookedTimes.forEach(function(bookedTime) {
                bookedTime.timespan.forEach(function(hour) {
                    occupiedHours.push(hour);
                });
            });

            this.hours = [];
            var hitStartDate = false;
            var hitEndDate = false;
            var lastHour = false;
            for(var i = 0; i <= 24; i++)
            {
                //check if hour is earlier than booking item start time
                if(i == parseInt(this.bookingItem.day_available_start_time.substr(0, 2)))
                {
                    hitStartDate = true;
                }
                                
                //check if hour is later than booking item end time
                if(i == parseInt(this.bookingItem.day_available_end_time.substr(0, 2))+1)
                {
                    hitEndDate = true;
                }

                //make last hour not selectable since it is out of range
                if(i == parseInt(this.bookingItem.day_available_end_time.substr(0, 2)))
                {
                    lastHour = true;
                }
                               
                if(!hitStartDate || hitEndDate)
                {
                    continue;
                }

                var hour = i < 10 ? '0' + i + ':00:00' : i + ':00:00'
                var booking = this.bookedTimes.find(x => x.start_date.split('T')[1] == hour);
                this.hours.push({ id: i, hour: i < 10 ? '0' + i + ':00' : i + ':00', booking: booking, selectable: !occupiedHours.some(x => x == i) && !lastHour, isStartTime: false });
            }

            setTimeout(() => {
                $("html, body").animate({ scrollTop: $("#BookingHoursAdd-times").position().top });
            }, 100);
        },
        emptyBookingValues()
        {
            this.newBookingTime.start_time = null;
            this.newBookingTime.end_time = null;
            this.newBookingTime.start_date = null;
            this.newBookingTime.end_date = null;
            this.indexOfStart = null,
            this.indexOfEnd = null;
            this.start_time = null;
        },
        selectTime(hour)
        {  
            message.removeMessage();         
            if(!hour.selectable)
            {
                return;
            }

            if(this.isBlocked(this.selectedDay))
            {
                message.setInfoMessage("Det går ej att välja någon tid denna dag eftersom dagen är blockerad.");
                return;
            }

            if(this.newBookingTime.start_time == null) {          
                this.newBookingTime.start_time = hour.hour;
                this.indexOfStart = this.hours.indexOf(hour);
                hour.isStartTime = true;
                hour.selected = true;
            }
            else if(hour.id - this.hours[this.indexOfStart].id >= this.bookingItem.duration_time)
            {
                message.setInfoMessage("Du har valt en längre bokningstid än vad som är möjligt. Maximal bokningstid är " + this.bookingItem.duration_time + " " + this.bookingItem.duration_unit);
                return;
            }
            else if(hour.id - this.hours[this.indexOfStart].id < 0)
            {
                message.setInfoMessage("Du har valt en sluttid som är tidigare än starttiden.");
                return;
            }
            else {
                
                this.indexOfEnd = this.hours.indexOf(hour);
                var timeOverAnotherBooking = false;
                for(var i = this.indexOfStart; i <= this.indexOfEnd; i++)
                {
                    if(!this.hours[i].selectable)
                    {
                        timeOverAnotherBooking = true;
                    } 
                }

                if(timeOverAnotherBooking)
                {
                    message.setInfoMessage("Du har valt en tid som ligger över en annan bokning. Vänligen ändra tid.");
                    return;
                }

                this.newBookingTime.end_time = this.hours[this.indexOfEnd+1].hour;
               
                this.hours.forEach(function(hour) {
                    hour.selected = false;                   
                });

                for(var i = this.indexOfStart; i <= this.indexOfEnd; i++)
                {
                    this.hours[i].selected = true;
                }
            }
        },
        save()
        {
            this.newBookingTime.booking_item_id = this.bookingItem.id;
            this.newBookingTime.start_date = this.newBookingTime.selected_date + ' ' + this.newBookingTime.start_time;
            this.newBookingTime.end_date = this.newBookingTime.selected_date + ' ' + this.newBookingTime.end_time;
            this.newBookingTime.duration_unit = this.bookingItem.duration_unit;
            this.newBookingTime.duration_time = this.indexOfEnd + 1 - this.indexOfStart;

            var self = this;
            this.$communicator.post('add-booking/', this.newBookingTime)
                .then(function(data)
                {                                    
                    message.setSuccessMessage();
                    self.$cache.updateCache('bookings', 'bookings/');
                    self.$cache.updateCache('incomingEvents', 'incoming-events/');
                    self.$router.push('/booking')  
                })
                .fail(function(error) {  
                    if(error.responseJSON.detail == 'already booked')
                    {
                        message.setValidationErrorMessage(["Du har redan en aktiv bokning registerad. Du kan endast ha en bokning registrerad i taget."]);  
                    }                   
                }) 
        },
        setNewStartTime()
        {
            this.newBookingTime.start_time = null;
            this.newBookingTime.end_time = null;
            this.hours.forEach(function(hour) {
                hour.selected = false;
                hour.isStartTime = false;
            });
        },
        isBlocked(day)
        {
           if(day != null) {
                for (const item of this.blockings) {                  
                    if (item.active_years_months_and_days.hasOwnProperty(this.currentDate.getFullYear())) {
                        const yearData = item.active_years_months_and_days[this.currentDate.getFullYear()];                
                        if (yearData.hasOwnProperty(this.currentDate.getMonth() + 1)) {                      
                            if (yearData[this.currentDate.getMonth() + 1].includes(day.day)) {
                                return true;
                            }
                        }
                    }
                }
           }
        
            return false;
        },
    },
    watch:
    {
        windowScroll(newvalue)
        {
            if(newvalue > 150)
            {
                this.showBookingBar = true;
            }
        }
    }
}
</script>
<template>
    <main class="opacity-white-bg" v-fade-in v-if="bookingItem != null" style="margin-bottom: 174px;">
        <section>           
            <div class="float-right">
                <div class="blocked blocked-dot"></div> = blockering
            </div>
            <h2>Skapa bokning av {{ bookingItem.name }}</h2>
            <p>Max bokningtid: {{ bookingItem.duration_time }} timmar</p>
            <div class="g-50">
                <div style="padding: 0px 10px">                           
                    <div class="row">
                        <div class="width33 month-link" @click="showPrevMonth">&lt; {{ currentDate.getMonth() == 0 ? 'december' : months[currentDate.getMonth()-1] }}  </div>
                        <div class="width33 month">{{ this.month }} {{ this.currentDate != null ? this.currentDate.getFullYear() : '' }}</div>
                        <div class="width33 text-right month-link" @click="showNextMonth">{{ months[currentDate.getMonth()+1] }} &gt;</div>
                    </div>
                    <div class="row days">
                        <div v-for="day in days" class="width14">
                        {{ day }}               
                        </div>
                    </div>   
                    <div v-for="(day,index) in allDaysInMonth" class="width14 float-left dates" :style="{ 'border-left': index % 7 == 0 ? '1px dashed #ddd' : '' }" :class="{'BookingAdd-today-date': day.isToday, 'wow-color': day.selected, 'blocked': isBlocked(day) }" @click="selectDate(day)">
                        <div class="day-content">
                            <i :style="{'opacity': day.isCurrentMonth ? '1' : '0.4', 'color': day.isSunday ? 'red': 'black' }">{{ day.isToday ? day.day + ' (idag)' : day.day }}</i>  
                            <div v-if="allBookingTimes != null">                 
                                <p v-for="booking in allBookingTimes.filter(x => day.date != undefined && x.start_date.split('T')[0] == day.dateString).slice(0, 2)">                    
                                        <i class="fa-regular fa-clock"></i> <span v-date="{date: booking.start_date, type: 'time'}"></span>-<span v-date="{date: booking.end_date, type: 'time'}"></span>                          
                                </p>
                                <p v-if="allBookingTimes.filter(x => day.date != undefined && x.start_date.split('T')[0] == day.dateString).length > 2">
                                    {{ allBookingTimes.filter(x => day.date != undefined && x.start_date.split('T')[0] == day.dateString).length -2 }} bokningar till
                                </p>
                            </div>
                        </div>             
                    </div>  
                </div>
            </div>  
            <div class="g-50" v-show="hours.length > 0" id="BookingHoursAdd-times">            
                <div class="BookingAdd-hour-content" :class="{'blocked': isBlocked(selectedDay)}">                
                    <div v-for="hour in hours" class="BookingAdd-hour" @mouseover="hour.hover = true" @mouseleave="hour.hover = false" @click="selectTime(hour)" :style="{ 'background-color': hour.selectable && hour.hover ? '#ddd' : '', 'cursor': hour.selectable ? 'pointer': 'auto'  }">                      
                        
                        <div class="BookingAdd-hour-text">{{hour.hour }}</div>
                        
                        <div class="BookingAdd-booking-selected" v-if="hour.selected == true" :style="{ 'height': 30 + 'px' }">
                            <span style="margin: 5px 9px; display: block;" v-if="hour.isStartTime">{{ newBookingTime.start_time }} - {{ newBookingTime.end_time }}</span>
                        </div>

                        <div class="BookingAdd-booking-occupied" v-if="hour.booking != undefined" :style="{ 'height': hour.booking.duration_time * 30 + 'px' }">
                            <p>
                                <span v-date="{date: hour.booking.start_date, type: 'time'}"></span>-<span v-date="{date: hour.booking.end_date, type: 'time'}"></span> &nbsp;
                                <span v-if="hour.booking.comments != null && hour.booking.comments.includes('Återkommande bokning:')"> {{ hour.booking.comments }}</span>
                                <span v-else> {{ hour.booking.created_by_user != null ? hour.booking.created_by_user.full_name : 'Publik bokning' }}</span>
                            </p>
                        </div>                      
                    </div>
                </div>
            </div>  
            <div class="BookingHoursAdd-booking-times wow-color" v-fade-in>
                <div>
                    1. Välj datum 
                    <h2> {{ newBookingTime.selected_date  }} </h2>   
                </div>
                <div>
                    <span :style="{ 'opacity': newBookingTime.selected_date == null ? '0.3' : '1', 'margin': '0px'}">2. Välj Starttid</span>
                    <h2>{{newBookingTime.start_time }} <button class="btn btn-gray" v-if="newBookingTime.start_time != null" @click="setNewStartTime">Ändra starttid</button></h2>
                </div>
                <div>
                    <p :style="{ 'opacity': newBookingTime.start_time == null ? '0.3' : '1', 'margin': '0px'}" class="margin-top-15">
                        3. Välj Sluttid 
                    </p>
                    <h2>{{newBookingTime.end_time }}</h2>
                </div>
                <div class="pc-only">
                    <button class="btn margin-top-15 BookingHoursAdd-confirm-btn" v-if="newBookingTime.end_time != null" @click="showSummary = true">Slutför bokningen</button>                           
                </div>
                <button class="btn mobile-only margin-top-15 BookingHoursAdd-confirm-btn mobile-100w-btn" v-if="newBookingTime.end_time != null" @click="showSummary = true">Slutför bokningen</button>                           
                  
            </div> 
        </section>
        <div v-if="showSummary">
            <PopUp>
                <h2>Bokning av {{ bookingItem.name }}</h2>
                <span>
                    Starttid: {{ newBookingTime.selected_date }} {{ newBookingTime.start_time }} <br>
                    Sluttid: {{ newBookingTime.selected_date }} {{ newBookingTime.end_time }} <br>
                </span>           
                <br>
                <label>Lägg till en kommentar (frivillig, kommer bara synas för dig)</label>
                <textarea v-model="newBookingTime.comments"></textarea>

                <div class="info BookingAdd-info-box">
                    <b style="text-transform: uppercase;">Läs igenom följande information innan du klickar på slutför bokningen:</b>
                    <div class="margin-top-15" v-html="bookingItem.rules"></div>
                </div>
                <div class="margin-top-15 float-right">
                    <button class="btn btn-gray" @click="showSummary = false">Gå tillbaka och ändra i bokningen</button>                   
                    <button class="btn" @click="save">Slutför bokningen</button>
                </div>
            </PopUp>
        </div>
    </main>
</template>
<style scoped>
    section{
        width: 100%;
        overflow: auto;
    }

    .row{
        overflow: auto;
    }

    .day-content
    {
        margin: 0px 5px 0px 5px;
        height: 120px;
        
    }
    
    .day-content:hover
    {
       border: 3px solid green;
       margin: 0px 2px 0px 2px;
       cursor: pointer;
       height: 114px;
    }

    .day-content p
    {
        font-size: 11px;
        background-color: rgb(245, 150, 150);
        padding:5px;
        margin:1px;
    }
    
    .row div{
        padding:10px 0px;

    }
    
    .dates {
        padding:4px 0px;
        border-right: 1px dashed #ddd;
   
        border-bottom: 1px dashed #ddd;

    }
    
    .month{
        text-align: center;
        font-weight: bold;
        text-transform: uppercase;
    }

    .month-link
    {
        cursor: pointer;
    }
    
    .days{
        text-align: center;
        font-weight: bold;
    } 
    
    .days div{
        border-right: 1px dashed #ddd;
        border-top: 1px dashed #ddd;
        border-bottom: 1px dashed #ddd;
        
    }

    .days div:first-child{
        border-left: 1px dashed #ddd;
    }
    .row div{
        float: left;
    }

    .width14
    {
        width: calc(14.2% - 2px);
        overflow: hidden;
    }

    .BookingAdd-hour
    {
        border-top: 1px dashed #ddd;
        height: 30px;
        position: relative;
    }
    
    .BookingAdd-hour-content
    {
        margin-top:56px; 
    }

    .BookingAdd-today-date
    {
        background-color: #ddd;
    }
    .BookingAdd-booking-occupied
    {
        background-color: rgb(245, 150, 150);
        width: 80%;
        height: 30px;
        float: left;
        margin-top: 0px;
        margin-left: 100px;
        position: absolute;
        top: 0px;
        z-index: 1;
        border-bottom: 1px solid red;
    }

    .BookingAdd-booking-selected
    {
        background-color: rgb(243, 235, 161);
        width: 80%;
        height: 30px;
        float: left;
        margin-top: 0px;
        margin-left: 100px;
        position: absolute;
        top: 0px;
        z-index: 1;
        border-bottom: 1px solid  rgb(243, 235, 161);
    }

    .BookingAdd-booking-occupied p
    {
        margin: 10px;
    }

    .BookingAdd-hour-text
    {
        margin-top: -11px;
        width:45px;
        padding-bottom: 12px;
    }

    .BookingAdd-info-box
    {
        padding:15px;
        margin-top: 15px;
        margin-bottom: 15px;
    }
   
   textarea
   {
    height: 200px;
   }
  
   .BookingHoursAdd-booking-times
    {
        padding:14px 10px 25px 10px;
        margin-top:30px; 
        border-radius:5px; 
        text-align: center;
        overflow: auto;
        position: fixed;
        bottom: 0px;
        width: 100%;
        left: 0px;
        z-index: 1001;
        height: 53px;
    }

    .BookingHoursAdd-booking-times div{
        float: left;
        width: 25%;
        height: 100px;
    }
   
    .blocked-dot
    {
        display: inline-block;
        width: 20px;
        height: 20px;
        border-radius: 10px;
        margin-bottom: -4px;
    }

    @media only screen and (max-width: 1000px) {
        .BookingHoursAdd-booking-times
        {          
            height: 123px;
        }

        .BookingHoursAdd-booking-times div{
            float: left;
            width: 33%;
        }
    }


</style>