<script>
export default {
    props: ['bookings', 'blockings','selectedDay'],
    data() {
        return {
            currentYear: new Date().getFullYear(),
            currentMonth: new Date().getMonth(),
            weekdays: ['Mån', 'Tis', 'Ons', 'Tors', 'Fre', 'Lör', 'Sön'],             
        };
    },
    computed: {
        monthName() {
            return new Date(this.currentYear, this.currentMonth).toLocaleString('sv-SE', { month: 'long' });
        },
        daysInMonth() {
            return new Date(this.currentYear, this.currentMonth + 1, 0).getDate();
        },
        firstDayOfMonth() {
            let day = new Date(this.currentYear, this.currentMonth, 1).getDay();
            return day === 0 ? 6 : day - 1; // move söndag from 0 to 6, and adjust other days
        }       
    },   
    methods: {
        prevMonth() {
            this.selectedDay.day = null;
            if (this.currentMonth === 0) {
                this.currentMonth = 11;
            this.currentYear--;
            } else {
            this.currentMonth--;
            }
            this.selectedDay.day= null;
            this.selectedDay.date= null;
            this.selectedDay.isBlocked= false;
        },
        nextMonth() {
            this.selectedDay.day = null;
            if (this.currentMonth === 11) {
                this.currentMonth = 0;
                this.currentYear++;
            } else {
            this.currentMonth++;
            }
            this.selectedDay.day= null;
            this.selectedDay.date= null;
            this.selectedDay.isBlocked= false;
        },
        selectDayAndDate(day) {
            this.selectedDay.day = day;
            this.selectedDay.date = new Date(this.currentYear, this.currentMonth, day);
            this.selectedDay.isBlocked = this.isBlocked(day);
        },   
        isSunday(day) {
            let date = new Date(this.currentYear, this.currentMonth, day);
            return date.getDay() === 0; // Söndag in JS is 0
        },           
        isBlocked(day)
        {
            for (const item of this.blockings) {
                
                if (item.active_years_months_and_days.hasOwnProperty(this.currentYear)) {
                    const yearData = item.active_years_months_and_days[this.currentYear];                
                    if (yearData.hasOwnProperty(this.currentMonth + 1)) {                      
                        if (yearData[this.currentMonth + 1].includes(day)) {
                            return true;
                        }
                    }
                }
            }
            return false;
        },
        getBookingsForDay(day) {
            let dateString = `${this.currentYear}-${String(this.currentMonth + 1).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
            return this.bookings.filter(booking => booking.formatted_start_date == dateString);
        }       
    }
};
</script>
<template>
    <div class="header">
        <a @click="prevMonth">Föregående</a>
        <h2>{{ monthName }} {{ currentYear }}</h2>
        <a @click="nextMonth">Nästa</a>
    </div>
    <div class="weekdays">
        <div v-for="day in weekdays" :key="day">{{ day }}</div>
    </div>
    <div class="days">
        <div v-for="blank in firstDayOfMonth" :key="'b'+blank" class="empty"></div>
        <div v-for="day in daysInMonth" :key="day" class="day" @click="selectDayAndDate(day)" :class="[isBlocked(day) ? 'blocked': '', day == selectedDay.day ? 'selected-day': '']">
            <span :class="{ 'sunday': isSunday(day) }">{{ day }}</span>
            <div v-for="(booking, index) in getBookingsForDay(day).slice(0, 3)" :key="booking.formatted_start_date_and_time" class="booking">                  
            <i class="fa-regular fa-clock"></i>  {{ booking.formatted_start_date_and_time.split(' ')[1] }} - {{ booking.formatted_end_date_and_time.split(' ')[1] }}
            </div>
            <div v-if="getBookingsForDay(day).length > 3" class="more-bookings">
                +{{ getBookingsForDay(day).length - 3 }} fler
            </div>
        </div>
    </div>
</template>
<style scoped>
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;   
}

.weekdays, .days {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 5px;
    text-align: center;
}

.weekdays div {
    font-weight: bold;
}

.day, .empty {
    background: #efdab1;
    padding: 22px 10px 10px 10px;
    border: 1px solid #d3c499;
    height: 73px;
    border-radius: 3px;
    position: relative;
}

.day{
    cursor: pointer;
}

.day:hover {
    background-color: #f0e4cb !important;
}

.day span {
    position: absolute;
    top: 2px;
    left: 2px;
    font-style: italic;
}

.sunday {
    color: red;
}

.booking {
    font-size: 10px;
    text-align: left;
    padding: 2px 5px;
    margin-bottom: 1px;
    background-color:rgb(245, 150, 150);
    border-radius: 4px;
}

.more-bookings {
    font-size: 13px;
}

.selected-day {
    background-color: rgb(188 221 124) !important;
}
</style>