<script>
import AdminLink from '../components/AdminLink.vue'
import List from '../components/List/List.vue'
export default {
    components:{ AdminLink, List },
    created() {     
        this.$cache.createCache('boardMembers', 'board-members/') 
    },
    data() {
        return {  
            userMessage: null,          
            customer: this.$store.state.customer,        
            sorting: [
            { name: 'board_member_type', displayName: 'Titel' },
            { name: 'user_name', displayName: 'Namn' }
        ]
        }
    }, 
    computed: {
        boardMembers() 
        {  
            return this.$store.state.boardMembers != null ? this.$store.state.boardMembers : []; 
        },
        columns() {

            var columns = [             
                { name: 'board_member_type', displayName: 'Titel', width: 160, hideFilter: true },
                { name: 'user_name', displayName: 'Namn', width: 300, hideFilter: true},
                { name: 'election_period', displayName: 'Vald för följande period', width: 300, hideFilter: true }]

                if(!this.customer.disable_user_messages)
                {
                    columns.unshift({ name: 'send', displayName: 'M', isSendUserMessage: true, align: 'center', width: 30, hideFilter: true});
                }

            return columns;
        }
    }
}
</script>
<template>
    <div v-if="boardMembers != undefined" class="overflow-auto">    
        <AdminLink :buttonText="'Redigera ' + this.$store.state.settings.BoardMember.singular" :sectionToEdit="'adminBoardMembers'"></AdminLink>
        <List :items="boardMembers" :columns="columns" :nameOfItems="this.$store.state.settings.User.plural" :sorting="sorting" :noOfFrozenColumns="2" :hideExcelExport="true"></List>
        <div v-if="boardMembers.length == 0">
            Det finns ingen {{this.$store.state.settings.BoardMember.singular}} än så länge.
        </div>
    </div>
</template>
<style scoped>

</style>