<script>
import List from '@/components/List/List.vue'
import Form from '@/components/Form/Form.vue'
import PopUp from '@/components/PopUp.vue'
import ToAdmin from '@/components/ToAdmin.vue'
import message from '@/helpers/message'
import UserInstruction from '@/components/UserInstruction.vue'
export default {
    components:{ List, Form, PopUp, ToAdmin, UserInstruction },
    created() {  
        if(this.user.user_level != 'SamfallifyCrew' && this.user.user_level != 'Redaktör')
        {
            this.$router.push('/login');
        } 
       
        this.$cache.createCache('workGroups', 'work-groups/');
    },
    data() {
        return {   
            columns: [                             
                { name: 'edit', displayName: '<i class="fa-solid fa-pen color-black"></i>',  title: 'Redigera', icon: 'fa-solid fa-pencil', align:'center', function: this.edit, width: 25, hideFilter: true, hideIfEdit: true},
                { name: 'delete', displayName: '<i class="fa-solid fa-trash color-black"></i>', title: 'Ta bort', isDelete: true, align:'center', width: 25, hideFilter: true, hideIfEdit: true},         
                { name: 'name', displayName: 'Namn', width: 350, hideFilter: false},                             
                { name: 'sort_order', displayName: 'Sorteringsordning', width: 130, hideFilter: true, align: 'center' },
                { name: 'noOfMembers', displayName: 'Antal', width: 60, hideFilter: true},               
                { name: 'members', displayName: 'Medlemmar', width: 700, hideFilter: true}, 
                { name: 'description', displayName: 'Beskrivning', width: 600, hideFilter: false},                             
                { name: 'created_date', displayName: 'Skapad', width: 100, hideFilter: true},
                { name: 'created_by', displayName: 'Skapad Av', width: 200},
              
            ], 
            sorting: [
                { name: 'name', displayName: 'Namn' },       
                { name: 'sort_order', displayName: 'Sorteringsordning' },       
                { name: 'created_date', displayName: 'Skapad' },           
                { name: 'created_by', displayName: 'Skapad Av' },           
            ],
            editItem: null,
            form: [                   
                    { sections: [
                            { sectionName: 'Namn, beskrivning och sorteringsordning', classes: "g-100", inputs: [ 
                                { type: 'text', prop: 'name', title: 'Namn', classes: "g-100", required: true },
                                { type: 'textArea', prop: 'description', title: 'Beskrivning', classes: "g-100" },
                                { type: 'number', prop: 'sort_order', title: 'Sorteringsordning', classes: "g-100" }, 
                                ]
                            }, 
                            { sectionName: 'Medlemmar', classes: "g-100", inputs: [
                                { type: 'multiSelect', prop: 'user_ids', title: 'Välj de medlemmar som ska ingå i arbetsgruppen',  selectItems: 'users', classes: "g-100" },  
                                ]
                            },
                            { sectionName: 'Forumet', classes: "g-100", showIf: { prop: 'id', method: 'equals', value: 0 }, inputs: [
                                { type: 'switch', prop: 'add_to_forum', title: 'Skapa även upp en kategori i forumet där medlemmarna kan kommunicera med arbetsgruppen', classes: "g-100" },  
                                ]
                            }
                        ]
                    }                  
            ],
        }
    },   
    methods:
    {
        edit(item)
        {
            this.editItem = item;
        },
        add()
        {
            this.editItem = { id: 0, name: '', description: '', user_ids: [], add_to_forum: false };
        },
        delete(id)
        {
            var self = this;
            this.$communicator.post('work-groups/' + id + '/delete')
                .then(function(data)
                {                 
                    self.getWorkGroups();
                    message.setSuccessMessage();
                })
                .fail(function(error) {                  
                })  
        },
        getWorkGroups()
        {
            this.editItem = null;
            this.$cache.updateCache('workGroups', 'work-groups/');
            this.$cache.updateCache('forums', 'forums/');
        }
    },
    computed:
    {
        user() {return this.$store.state.user; },
        workGroups() {  
            return this.$store.state.workGroups != undefined ? this.$store.state.workGroups.map(x => (
                {
                    id: x.id, 
                    created_date: this.$formatters.date(x.created_date, 'date'), 
                    name: x.name,    
                    description: x.description,  
                    noOfMembers: x.members.length,
                    members: x.members.map(x => x.user.full_name).join(", "),
                    user_ids: x.members.map(x => x.user.id),
                    created_by: x.created_by_user.full_name,
                    sort_order: x.sort_order
                })) : []
            }       
    }
}
</script>
<template>
    <div v-fade-in>
        <main class="opacity-admin-bg overflow-auto position-relative">
            <div class="float-right">
                <button id="List-sort-btn" class="btn" @click="add">Lägg till en {{ this.$store.state.settings.WorkGroup.singular }}</button>
                <UserInstruction :page="this.$route.name"></UserInstruction>
            </div>
            <ToAdmin :section="this.$store.state.settings.WorkGroup.plural"></ToAdmin>
            <h2>{{ this.$store.state.settings.WorkGroup.plural }}</h2>          
                <div v-if="editItem != null">
                    <PopUp>
                    <Form :form="form" :item="editItem" :url="'work-groups/'" :getItemFunction="getWorkGroups" :inEditMode="true"></Form>
                    </PopUp>
                </div>
            <List :items="workGroups" :columns="columns" :noOfFrozenColumns="3" :deleteFunction="delete" :isEditing="editItem != null" :nameOfItems="'arbetsgrupper'" :sorting="sorting" :backgroundColor="this.$colors.list.backgroundColor" :borderColor="this.$colors.list.borderColor"></List>        
            <div v-show="workGroups.length == 0">
                <p>Du har inte lagt till några {{ this.$store.state.settings.WorkGroup.plural }} ännu.</p>
            </div>
        </main>
    </div>
</template>
<style scoped>
    .AdminNotifications-edit-panel
    {
        padding:10px;
        margin: 15px 0px;
        border:1px solid #ddd;
    }
</style>