<script>
    export default {
        props: ['prop', 'allowClear', 'selectedItemSysID', 'selectID', 'currentItemDisplayName', 'selectItems', 'hasValidationErrors', 'index'],
        emits: ['update:selectedItemSysID'],
        data() {
            return {
                availableItems: [],
                dispDropdownList: false,
                highlightedItem: null,
                searchString: '',
                selectedItemName: '' ,
                id: this.index == undefined ? this.prop : this.prop + this.index           
            }
        },
        methods: {
            selectOption(item) {
               
                this.$emit('update:selectedItemSysID', item.id);
                this.dispDropdownList = false;
                this.selectedItemName = item.name;
                this.availableItems.forEach(function (item) {
                    item.selected = false;
                });
                item.selected = true;
            },
            clear() {
                this.$emit('update:selectedItemSysID', null);
                this.selectedItemName = '';
                this.highlightedItem = null;
                this.availableItems.forEach(function (item) {
                    item.selected = false;
                });
            },
            toggleOptions() {
                var self = this;
                this.dispDropdownList = !this.dispDropdownList;

                if (this.dispDropdownList) {

                    this.availableItems.forEach(function (item) {
                        if (self.selectedItemSysID === item.id) {
                            item.selected = true;
                            self.highlightedItem = item;
                        } else {
                            item.selected = false;
                        }
                        item.display = true;
                    });

                    this.searchString = '';
                     
                    $("#app").append($("#_Select-select-" + this.id));
                     //element.getBoundingClientRect().top
                    var elmPosition = document.getElementById("_select-content" + this.id).getBoundingClientRect();
                    $("#_Select-select-" + this.id).css({width: elmPosition.width-7 + 'px', top: elmPosition.top + 32 + $(window).scrollTop() + 'px', left: elmPosition.left + 'px'});
                    
                    //TODO - get it workin without setTimeout
                    setTimeout(function () {              
                        $("#" + self.id + "-search-string").get(0).focus();              
                    }, 50);

                    this.scrollToElement($("._Select-selected-item")[0]);
                }

            },
            scrollToElement(elm) {
                var self = this;
                if (elm != undefined) {
                    //TODO - get it workin without setTimeout
                    setTimeout(function () {
                        var scrollTop = elm.offsetTop - $("#_Select-ul-" + self.id).outerHeight();
                        $("#_Select-ul-" + self.id).scrollTop(scrollTop + 40);
                    }, 50)
                }
            },
            search(event) {
                var self = this;

                if (event.keyCode === 13) { //do if keyboard press is enter
                    this.$emit('update:selectedItemSysID', this.highlightedItem.id);
                    this.selectedItemName = this.highlightedItem.name;
                    this.dispDropdownList = false;

                }
                else if (event.keyCode === 40 || event.keyCode === 38) { //do if keypress is arrow is down or up

                    if (self.highlightedItem === null) {
                        self.highlightedItem = self.availableItems[0];
                    }
                    else {
                        var index = self.availableItems.findIndex(item => item.id === self.highlightedItem.id);
                        var indexToHighlight = event.keyCode === 40 ? index + 1 : index - 1;

                        if (indexToHighlight > -1 && index > -1 && indexToHighlight < self.availableItems.length) {
                            var foundNextVisibleItem = false;
                            while (!foundNextVisibleItem) {
                                if (self.availableItems[indexToHighlight].display) {
                                    self.highlightedItem = self.availableItems[indexToHighlight];
                                    foundNextVisibleItem = true;
                                }
                                else {
                                    indexToHighlight = event.keyCode === 40 ? indexToHighlight + 1 : indexToHighlight - 1;
                                }

                                if (indexToHighlight < 0 || indexToHighlight >= self.availableItems.length) {
                                    foundNextVisibleItem = true;
                                }
                            }
                        }
                    }
                }
                else {
                    var firstHit = true;
                    this.availableItems.forEach(function (item) {
                        var isIncluded = item.name.toLowerCase().includes(self.searchString.toLowerCase());
                        item.display = isIncluded;
                        if (isIncluded && firstHit) {
                            self.highlightedItem = item;
                            firstHit = false;
                        }
                    });
                }

                this.scrollToElement($("._Select-highlighted-item")[0]);
            },
            setHighlightedItem(item) {
                this.highlightedItem = item;
            },
            populateAvailableItems()
            {
                if(this.selectItems == undefined)
                {
                    this.selectedItemName = this.currentItemDisplayName;
                    this.availableItems = this.$store.state.categories
                        .filter(p => p.prop_name == this.prop)
                        .map(function(item) {
                            return {id: item.name, name: item.name, display: true, selected: false }
                        });
                }
                else if(this.selectItems == 'users')           
                {       
                    this.availableItems =  this.$store.state.users                  
                        .map(function(item) {
                            return {id: item.id, name: item.full_name, display: true, selected: false }
                        });
                    
                        var selectedItem = this.availableItems.find(x => x.id == this.currentItemDisplayName);
                        this.selectedItemName = selectedItem != undefined ? selectedItem.name : '';
                } else if(this.selectItems == 'hours')
                {
                    for(var i = 0; i <= 23; i++)
                    {
                        var hour = i < 10 ? '0' + i + ':00' : i + ':00';
                        this.availableItems.push({id: hour, name: hour, display: true, selected: false });
                    }
                    var selectedItem = this.availableItems.find(x => x.id == this.currentItemDisplayName);
                    this.selectedItemName = selectedItem != undefined ? selectedItem.name : '';

                } else if(this.selectItems == 'quarters')
                {
                    for(var i = 1; i <= 23; i++)
                    {
                        var self = this;
                        var times = ['00', '15', '30', '45']
                        times.forEach(function(time) {
                            var hour = i < 10 ? '0' + i + ':' + time : i + ':' + time;
                            self.availableItems.push({id: hour, name: hour, display: true, selected: false });         
                        })
                    }
                    var selectedItem = this.availableItems.find(x => x.id == this.currentItemDisplayName);
                    this.selectedItemName = selectedItem != undefined ? selectedItem.name : '';

                } else if(this.selectItems == 'numbers')
                {
                    for(var i = 1; i <= 12; i++)
                    {
                        this.availableItems.push({id: i.toString(), name: i.toString(), display: true, selected: false });
                    }
                    var selectedItem = this.availableItems.find(x => x.id == this.currentItemDisplayName);
                    this.selectedItemName = selectedItem != undefined ? selectedItem.name : '';
                }
                else if(this.selectItems == 'yesno')
                {
                    this.availableItems.push({id: true, name: 'Ja', display: true, selected: false });
                    this.availableItems.push({id: false, name: 'Nej', display: true, selected: false });
                    
                    var selectedItem = this.availableItems.find(x => x.id == this.currentItemDisplayName);
                    this.selectedItemName = selectedItem != undefined ? selectedItem.name : '';
                }
                else if(this.selectItems == 'daysInWeek')
                {
                    this.availableItems.push({id: 1, name: 'Måndag', display: true, selected: false });
                    this.availableItems.push({id: 2, name: 'Tisdag', display: true, selected: false });
                    this.availableItems.push({id: 3, name: 'Onsdag', display: true, selected: false });
                    this.availableItems.push({id: 4, name: 'Torsdag', display: true, selected: false });
                    this.availableItems.push({id: 5, name: 'Fredag', display: true, selected: false });
                    this.availableItems.push({id: 6, name: 'Lördag', display: true, selected: false });
                    this.availableItems.push({id: 0, name: 'Söndag', display: true, selected: false });
                    
                    var selectedItem = this.availableItems.find(x => x.id == this.currentItemDisplayName);
                    this.selectedItemName = selectedItem != undefined ? selectedItem.name : '';
                } else {
                    this.availableItems = this.selectItems;
                    var selectedItem = this.availableItems.find(x => x.id == this.currentItemDisplayName);
                    this.selectedItemName = selectedItem != undefined ? selectedItem.name : '';
                }
            }
        },
        watch: {
            selectedItemSysID(newValue) {
                var self = this;
                self.selectedItemName = '';                
                this.availableItems.forEach(function (item) {
                    item.display = true;
                    item.selected = false;
                    if (item.id === newValue) {
                        self.selectedItemName = item.name;
                        item.selected = true;
                        self.highlightedItem = item;
                    }
                });
                this.$emit('update:selectedItemSysID', newValue);               
            }
        },
        created() {
            var self = this;
            this.populateAvailableItems();
            

           

            //close if clicking outside search section.
            $(document).mousedown(function (e) {
                var container = $("#_Select-select-" + self.id);
                var inputArea = $("#_select-input" + self.id);
                // if the target of the click isn't the container nor a descendant of the container (plus input)
                if (!container.is(e.target) && container.has(e.target).length === 0 && !inputArea.is(e.target) && inputArea.has(e.target).length === 0) {
                    self.dispDropdownList = false;
                    self.highlightedItem = null;
                    self.searchString = '';
                    $("#_select-content" + self.id).append($("#_Select-select-" + self.id));
                }
            });
        }
    }
</script>

<template>
    <div class="_Select-select" :id="'_select-content'+ id">                  
        <span class="_Select-clear" @click="clear()" v-show="allowClear && selectedItemName !== ''">
            <i class="fa fa-times"></i>
        </span>
        <div @click="toggleOptions">
            <div :id="'_select-input' + id" :class="['select-input','_Select-chosenItem-input', hasValidationErrors ? 'validation-error-border':'']">
                <span v-show="selectedItemName != ''" style="color: #555555"><span v-html="selectedItemName"></span></span>
                <span v-show="selectedItemName == '' || selectedItemName == undefined || selectedItemName == null" style="color:#999999">Välj...</span>
                <div class="fa fa-caret-down" v-show="!dispDropdownList"></div>
                <div class="fa fa-caret-up" v-show="dispDropdownList"></div>
            </div>
        </div>

        <div class="_Select-dropdown" v-show="dispDropdownList" :id="'_Select-select-' + id">
            <div class="_Select-select-input-section" v-show="availableItems.length > 5">
                <input type="text" class="_Select-search" :id="id + '-search-string'" v-model="searchString" @keyup="search" autocomplete="off" />
            </div>
            <ul :id="'_Select-ul-' + id">
                <li v-for="item in availableItems" @click="selectOption(item)" @mouseover="setHighlightedItem(item)" v-show="item.display" :class="[item.selected ? '_Select-selected-item' : '', highlightedItem != null && highlightedItem.id == item.id ? 'wow-color' : '']">
                    <span>{{item.name}} </span>
                </li>
            </ul>

        </div>                  
    </div>  
</template>
<style scoped>
 ._Select-dropdown li {
    list-style: none;
    margin: 0px;
    padding: 7px 4px 7px 4px !important;
  }

  ._Select-dropdown li span {
      margin-left: 10px;
  }

._Select-dropdown ul {
  list-style: none;
  border: 1px solid #ccc;
  border-top: 0px;
  padding: 0px;
  margin: 0px;
  font-size: 9pt;
  cursor: pointer;
  max-height: 200px;
  overflow-y: auto;
}
    .select-input
    {
        width: calc(100% - 29px);
    }

    li{
        text-align: left;
    }
    ._Select-select {
        position: relative;
        margin: 0px;
        padding: 0px;
        line-height: normal !important;
    }

    ._Select-chosenItem-input {
        caret-color: transparent;
        cursor: pointer;
    }


    ._Select-select .fa {
        position: absolute;
        color: #888;
        right: 14px;
        top: 10px;
        cursor: pointer;
    }

    ._Select-select input {
        width: calc(100% - 12px);
    }

    ._Select-select-input-section {
        padding: 4px;
        border-left: 1px solid #ccc;
        border-right: 1px solid #ccc;
    }

    ._Select-selected-item {
        background-color: #ddd;
    }

  

    ._Select-dropdown {
        position: absolute;
        top: 35px;
        left: 0px;
        z-index: 1003;
        width: 100%;
        background-color: white;
    }

    ._Select-clear {
        position: absolute;
        width: 20px;
        top: 2px;
        right: 19px;
        z-index: 1002;
        font-size: 10px;
    }

    ._Select-search {
        padding: 4px 4px 4px 6px !important;
    }

    div.error, div.error:focus {
        border: 1px solid #d9534f;
    }

    .validation-error-border
    {
        border: 1px solid rgb(230, 70, 70);
        background-color: rgb(250, 202, 202) !important;
        color: rgb(230, 70, 70);
    }
</style>