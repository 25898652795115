<script>
import PopUp from '@/components/PopUp.vue'
import message from '@/helpers/message'
import Form from '@/components/Form/Form.vue'
import List from '@/components/List/List.vue'
  export default {
    components:{ PopUp, Form, List },
    props: ['blockingPopup','blockings', 'getBookings', 'bookingItemId'],
    data() {
        return {
            blockingItem: null,
            columns: [                             
                { name: 'edit', displayName: '<i class="fa-solid fa-pencil color-black"></i>', title:'Redigera', icon: 'fa-solid fa-pencil', align:'center', function: this.edit, width: 25, hideFilter: true, hideIfEdit: true},
                { name: 'delete', displayName: '<i class="fa-solid fa-trash color-black"></i>',  title: 'Ta bort', isDelete: true, align:'center', width: 25, hideFilter: true, hideIfEdit: true},                
                { name: 'period', displayName: 'Period', width: 300, hideFilter: true},
                { name: 'description', displayName: 'Anledning till blockering', width: 500, hideFilter: true},               
                { name: 'formatted_created_date', displayName: 'Skapad', width: 100, hideFilter: true},
                { name: 'created_by_full_name', displayName: 'Skapad Av', width: 200, hideFilter: true},            
            ],
            form: [                   
                { sections: [{ sectionName: 'Blockeringsinformation', classes: "g-100", inputs: [ 
                            { type: 'dateAndTime', prop: 'start_date', title: 'Från', classes: "g-100", required: true, selectItems: 'hours' },                         
                            { type: 'dateAndTime', prop: 'end_date', title: 'Till', classes: "g-100", required: true, selectItems: 'hours' },                         
                            { type: 'textArea', prop: 'description', title: 'Anledning till blockering', classes: "g-100", required: true }                                                   
                        ]
                    },{ sectionName: 'Observera!', classes: "g-100", inputs: [                                               
                            { type: 'infoText', infoText: 'Kom ihåg att <b>avboka</b> alla bokningar (om det finns några) som ligger inom det blockerade områden.', classes: "g-100" },                         
                        ]
                    }
                    ],
                        backgroundColor: this.$colors.form.backgroundColor,
                        borderColor: this.$colors.form.borderColor
                }],
            
        };
    },
    computed: {
       
    },
    created() {
        
    },
    methods: {
        add() {
            this.blockingItem = { id: 0, description: '', start_date: null, end_date: null, booking_item_id: this.bookingItemId }

        },
        edit(item) 
        {
            this.blockingItem = item;

        },
        delete(id)
        {
            var self = this;
            this.$communicator.post('bookings/blocking/' + id + '/delete')
            .then(function(data)
            {                 
                self.getBookings();
                message.setSuccessMessage();
            })
            .fail(function(error) {                  
            })  
        },
        getBookingsAndBlockings(){
            this.blockingItem = null;
            this.getBookings();

        }
    }
  };
</script>
<template> 
    <PopUp v-if="blockingPopup.isOpen" :width="'90%'" :bgColor="this.$colors.list.backgroundColor" style="text-align: left">
        <button class="btn btn-gray float-right" @click="blockingPopup.isOpen = false">Stäng</button>
        <button class="btn btn float-right" @click="add">Skapa ny blockering</button>
        <h2> {{ blockingItem ? 'Lägg till blockering' : 'Hantera blockeringar' }}</h2>  
        <div v-if="blockingItem != null" class="margin-bottom-15px" v-fade-in>
            <Form :form="form" :item="blockingItem" :url="'bookings/blocking/'" :getItemFunction="getBookingsAndBlockings" :inEditMode="true"></Form>
        </div>
        <div v-show="blockingItem == null">
            <List :items="blockings" :columns="columns" :noOfFrozenColumns="3" :deleteFunction="delete" :nameOfItems="'blockeringar'" :backgroundColor="this.$colors.list.backgroundColor" :borderColor="this.$colors.list.borderColor"></List>        
        </div>
    </PopUp> 
</template>
<style scoped>
</style>
  