<script>
import menuLinks from "@/helpers/menuLinks"
import Notification from '@/components/Notification.vue'
export default {
    components: {Notification },
    props: ['positionLeft'],
    data(){
        return {         
            selectedModule: 'Aktuellt',        
            hideMenuPages: menuLinks.hideMenuPages,
            links: menuLinks.getLinks(this.$store.state.settings)
        }
    },
    created()
    {     
        this.$cache.createCache('boardMembers', 'board-members/')                
        $(".menu-item").hover(function(){
            $(this).css("background-color", "yellow");
            }, function(){
            $(this).css("background-color", "pink");
        });
    },
    methods:
    {
        goTo(viewName)
        {
            this.$router.push({ name: viewName })
        } 
    },
    computed:
    {
        user()
        {
            return this.$store.state.user;
        },
        isAdmin()
        {
            return this.user.user_level == 'SamfallifyCrew' || this.user.user_level == 'SuperAdmin'
        },
        showMenu() {
            return !this.hideMenuPages.some(p => p == this.$route.name);
        },
        userWorkGroups() {  
            return this.$store.state.userWorkGroups != null ? this.$store.state.userWorkGroups : []; 
        },
        notifications() {        
            return this.$store.state.notifications != null ? this.$store.state.notifications : []; 
        },
        invitations() {  
            return this.$store.state.invitations;
         },       
        isPartOfBoard()
        {
            return this.$store.state.boardMembers != null && this.$store.state.settings.BoardMember.enabled ? this.$store.state.boardMembers.some(x => x.user_id == this.user.id) : false;
        },
        workGroupId()
        {
            return this.$route.params.id; 
        }
    }
}
</script>
<template>
    <section id="menu-section" :style="{left: positionLeft }"  v-show="showMenu" v-fade-in>
        <div style="margin-top:30px;">
            <img src="@/assets/imgs/new_logo_wheat.png" @click="goTo('home')" style="width: 90px;cursor:pointer" v-tooltip="'Gå till startsidan'">  
            <div v-for="(link,index) in links.filter(x => x.enabled)" :key="index" class="position-relative">
                <Notification :level="'menu'" :levelName="link.text" :notifications="notifications" :color="'#d98df6'" :right="'10px'"></Notification>
                <router-link :to="link.url" class="menu-item">
                        <i :class="[link.icon, link.name.some(p => p == this.$route.name) ? 'font-color' : '']"></i>
                        <span v-html="link.text"></span>
                        <hr class="border-color" v-if="link.name.some(p => p == this.$route.name)" v-fade-in />
                </router-link>          
            </div>  
            <div v-if="isPartOfBoard" class="position-relative">
                <Notification :level="'menu'" :levelName="'Styrelsen'" :notifications="notifications" :color="'#d98df6'" :right="'10px'"></Notification>
                <router-link :to="'/canvas-board'" class="menu-item">
                        <i class='fa-solid fa-people-line'></i>
                        <span v-html="this.$store.state.settings.BoardMember.singular"></span>
                        <hr class="border-color" v-if="this.$route.name == 'canvasBoard'" v-fade-in />
                </router-link>  
            </div>
            <div v-if="this.$store.state.settings.WorkGroup.enabled">  
                <div v-for="group in userWorkGroups" class="position-relative">
                    <Notification :level="'menu'" :levelName="group.name" :notifications="notifications" :color="'#d98df6'" :right="'10px'"></Notification>
                    <router-link :to="'/canvas/' + group.id" class="menu-item">
                            <i class="fa-solid fa-person-digging"></i>
                            <span v-html="group.name"></span>
                            <hr class="border-color" v-if="this.$route.name == 'canvasWorkGroup' && workGroupId == group.id" v-fade-in />
                    </router-link>  
                </div> 
            </div>              
        </div>
    </section>   
</template>
<style scoped>
    section{     
       float:left;  
       margin: 0px;    
        width:120px;
        opacity: 1;
        padding: 0px 10px;
        backdrop-filter: brightness(0.8) blur(4px);
        /* background: linear-gradient(90deg, rgba(88,68,38,1) 0%, rgba(119,95,58,1) 100%); */
        position: fixed;
        z-index: 999;
        overflow-y: auto;
        height: 100vh;
        overflow-x: hidden;
        font-family: 'Raleway', sans-serif;
    }

    .menu-visible {
        transition-timing-function: ease-in;
        transition: 0.7s;
        opacity:1;
        margin-top: 70px;
    }

    hr
    {
        border-width: 5px;
        border-top: 0px;
        border-radius: 5px;
        border-left: 0px;
        position: absolute; 
        width: calc(100% - 6px);
    }

    a{
        width: 100px;
        padding-top: 18px;
        height: 60px;
        display: inline-block;
        cursor: pointer;
        margin-top: 5px;
        margin-right: 10px;
        text-transform: uppercase;
        font-size: 12px;
        position: relative;
            
    }

    i {
        font-size: 24px;
        display: block;
        color: wheat;
        /* color: #6DA4D7; */
    }
    span{
        color: white;
        font-weight: bold;
        display: block;
        margin-top: 7px;
    }
    a:hover {
        text-decoration: none;
    }


    /* width */
::-webkit-scrollbar {
  width: 14px;
}

/* Track */
::-webkit-scrollbar-track {
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  /* background: red;  */
  background: rgba(0, 0, 0,0.2);
  border-radius: 6px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0,0.2);
}
</style>