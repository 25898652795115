// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@/assets/imgs/Bryggan.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "h1[data-v-0006d18b]{font-size:20px}td[data-v-0006d18b]{padding:5px 10px 2px 0}.AdminPublic-community-table[data-v-0006d18b]{margin-bottom:30px}.AdminPublic-publish-btn[data-v-0006d18b]{padding:15px 0;background-color:#58d64d!important;color:#fff;position:fixed;bottom:0;right:0;left:0;width:100%;font-size:16px;z-index:11}.AdminPublic-home-header[data-v-0006d18b]{position:absolute;top:300px;width:100%;text-align:center;font-size:27pt;text-shadow:-1px -1px 0 #000,1px -1px 0 #000,-1px 1px 0 #000,1px 1px 0 #000;font-family:Fredoka,sans-serif;font-weight:700;text-transform:uppercase;letter-spacing:2px;background-color:hsla(0,0%,100%,.4);padding:15px 0}.AdminPublic-divider[data-v-0006d18b]{width:100%;height:1px;margin-top:15px;margin-bottom:10px;filter:brightness(90%)}.top-public[data-v-0006d18b]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:no-repeat;height:600px;width:100%;background-size:cover;background-position:50%;margin-top:-120px;position:relative}.table-style div[data-v-0006d18b]{display:inline-block;padding:2px 10px 2px 0;width:50%}.AdminPublic-notification-item[data-v-0006d18b]{padding:15px;margin:5px 20px 0 0;margin-bottom:10px;display:inline-block;width:calc(50% - 50px);vertical-align:top;border-top:1px solid #ddd}.AdminPublic-notification-item i[data-v-0006d18b]{font-size:14px}.AdminImageVault-img-area[data-v-0006d18b]{float:left;color:#fff;font-size:11px;position:relative;padding:10px}.AdminImageVault-img-area img[data-v-0006d18b]{cursor:pointer;padding:5px;margin:auto;height:150px}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
