<script>
import Form from '../components/Form/Form.vue'
import Switch from '../components/Form/Switch.vue'
import Tabs from '../components/Tabs.vue'
import List from '../components/List/List.vue'
import UploadProfilePicture from '../components/UploadProfilePicture.vue'
export default {
    components: { Form, Tabs, Switch, List, UploadProfilePicture },
    data(){
        return {
            user: null,
            userEmailSettings:{},
            notifications: { 
                invitation: true, 
                invitation_reminder: false, 
                user_message: true, 
                forum_thread: false, 
                booking: true, 
                booking_reminder: false, 
                notification_from_board: false,
                hasChanges: false,
            },
            columns: [                       
                { name: 'name', displayName: 'Gruppnamn', width: 200, hideFilter: true},
                { name: 'description', displayName: 'Beskrivning', width: 600, hideFilter: true},             
                { name: 'members', displayName: 'Deltagare', width: 1000, hideFilter: true},             
            ],           
            passwordItem: { old_password: null, new_password: null, new_password2: null },
            userLoaded: false,
            selectedTab: "Användaruppgifter",           
            passwordForm: [
                { sections: [
                    { sectionName: 'Lösenord', classes: "g-100", inputs: [
                        { type: 'password', prop: 'old_password', title: 'Nuvarande lösenord', classes: "g-100", required: true }, 
                        { type: 'password', prop: 'new_password', title: 'Nytt lösenord', classes: "g-100", required: true },
                        { type: 'password', prop: 'new_password2', title: 'Repetera nytt lösenord', classes: "g-100", required: true, identicalTo: 'new_password' }
                    ] 
                    }]
                },
            ]                 
        }
    },
    created()
    { 
        this.getUserSettings();
        this.getUserMailSettings();
        this.$cache.createCache('userWorkGroups', 'work-groups-for-user/'); 
        if(this.userWorkGroups != null && this.userWorkGroups.length > 0)
        {
            this.tabs.splice(4, 0, 'Arbetsgrupper');
        }
        
    },
    methods:
    {
        getUserSettings()
        {
            var self = this;   
            this.$communicator.get('user-settings/')
            .then(function(data)
            {             
                self.$store.commit('setEntity', { entityName: 'user', entity: { name: data.first_name + " " + data.last_name, id: data.id, user_level: data.user_level }});
                self.user = data;
            })
            .fail(function(error) {
                self.$apiErrorhandler.handleErrors(error, self.$route.fullPath)              
            }); 
        },
        getUserMailSettings()
        {
            var self = this;   
            this.$communicator.get('user-mail-settings/')
            .then(function(data)
            {             
                self.userEmailSettings = data
                self.userEmailSettings.hasChanges = false
            })
            .fail(function(error) {
            
            }); 
        },
        updateUserMailSettings()
        {
            var self = this;   
            this.$communicator.post('user-mail-settings/', this.userEmailSettings)
            .then(function(data)
            {             
                self.userEmailSettings.hasChanges = false;
                self.$message.setSuccessMessage('Härlig! Dina inställningar har uppdaterats')
            })
            .fail(function(error) {
            
            }); 
        },
        updateEmailSettings()
        {
            this.passwordItem = { old_password: null, new_password: null, new_password2: null }
            this.selectedTab = "Användaruppgifter"
        }
    },
    computed:
    {
        userWorkGroups() {  return this.$store.state.userWorkGroups != null ? this.$store.state.userWorkGroups.map(x => ( {
                name: x.name,
                members: x.members.map(y =>y.user.id == this.storedUser.id ? 'Du' : y.user.full_name).join(", "),
                description: x.description
            })) : []; },
        storedUser() {return this.$store.state.user; },
        tabs() {
            return this.$store.state.settings != null && this.$store.state.settings.UserSetting_profile_picture.enabled 
            ? ['Användaruppgifter','Profilbild', 'Notiser', 'Användarnivå', 'Byta lösenord']
            : ['Användaruppgifter', 'Notiser', 'Användarnivå', 'Byta lösenord']

        },
        settings() {
            return this.$store.state.settings;
        },
        userInfoForm() {
            return [
                { sections: [
                    { sectionName: 'Namn, e-mail och telefon', classes: "g-50", inputs: [
                        { type: 'text', prop: 'first_name', title: 'Förnamn', classes: "g-50", required: true }, 
                        { type: 'text', prop: 'last_name', title: 'Efternamn', classes: "g-50", required: true },
                        { type: 'text', prop: 'email', title: 'E-mail (Användarnamn)', classes: "g-100", required: true },
                        { type: 'text', prop: 'phone_number', title: 'Telefonnummer', classes: "g-100" }
                    ] },
                    { sectionName: 'Adress', classes: "g-50", inputs: [
                        { type: 'text', prop: 'address_street', title: 'Gata', classes: "g-80" }, 
                        { type: 'text', prop: 'address_number', title: 'Gatnr.', classes: "g-20" },
                        { type: 'text', prop: 'address_postal', title: 'Postnr.', classes: "g-30" },
                        { type: 'text', prop: 'address_city', title: 'Postort', classes: "g-70" }
                        ]
                    },
                    { sectionName: 'Fastighetsbeteckning', disabled: !this.$store.state.settings.UserSetting_form_property_designation.enabled, classes: "g-50", inputs: [
                        { type: 'text', prop: 'property_designation', title: '', classes: "g-100" }, 
                      
                        ]
                    }]
                }]
            }
    }
}
</script>
<template>
    <div v-if="settings.IsUserSettings" v-fade-in>
        <main class="opacity-white-bg">
            <h2>Dina Inställningar</h2>
            <Tabs :tabs="tabs" v-model:selectedTab="selectedTab">               
            </Tabs>
            <div v-if="user != null && selectedTab == 'Användaruppgifter'">
                <Form :form="userInfoForm" :item="user" :url="'user-settings/'" :getItemFunction="getUserSettings"></Form>
            </div>
            <div v-if="selectedTab == 'Profilbild'">
                <UploadProfilePicture></UploadProfilePicture>
            </div>
            <div v-if="selectedTab == 'Notiser'" class="overflow-auto">   
                <button class="btn float-right" v-show="this.$isMobile && userEmailSettings.hasChanges" @click="updateUserMailSettings">Spara ändringar</button> 
                <h6>Inbjudan/Kallelser</h6> 
                <table>
                    <tr>
                        <td width="60"><Switch :model="userEmailSettings" :prop="'invitation'"></Switch></td>
                        <td>Jag vill få inbjudan/kallelser till min email.</td>
                    </tr>
                    <tr>
                        <td width="60"><Switch :model="userEmailSettings" :prop="'invitation_reminder'"></Switch></td>
                        <td>Jag vill få en påminnelse per email dagen innan inbjudan/kallelsen äger rum.</td>
                    </tr>
                </table>          
                <h6>Bokningar</h6> 
                <table>
                    <tr>
                        <td width="60"><Switch :model="userEmailSettings" :prop="'booking'"></Switch></td>
                        <td>Jag vill få bekräftelse-email på de bokningar jag gör.</td>
                    </tr>
                    <tr>
                        <td width="60"><Switch :model="userEmailSettings" :prop="'booking_reminder'"></Switch></td>
                        <td>Jag vill få en påminnelse per email dagen innan bokningen.</td>
                    </tr>
                </table>                                       
                <h6>Forumet</h6> 
                <table>
                    <tr>
                        <td width="60"><Switch :model="userEmailSettings" :prop="'forum_thread'"></Switch></td>
                        <td>Jag vill bli meddelad per email om någon svara på ett inlägg jag skapat.</td>
                    </tr>                  
                </table>          
                <button class="btn float-right" v-show="userEmailSettings.hasChanges" @click="updateUserMailSettings">Spara ändringar</button>
            </div>
            <div v-if="selectedTab == 'Användarnivå'">
                Du är <span style="text-transform: lowercase; font-weight: bold;">{{ user.user_level }}</span>. 
                <br><br>
                <span v-if="user.user_level == 'Användare'"> Önskar du bli redaktör får du ta kontakt med någon av föreningens redaktörer.</span>
            </div>
            <div v-if="selectedTab == 'Arbetsgrupper'">
                Du är med i följande arbetsgrupp/grupper:
                <List :items="userWorkGroups" :columns="columns" :nameOfItems="'arbetsgrupper'" :noOfFrozenColumns="1" :hideExcelExport="true"></List>

            </div>
            <div v-if="user != null && selectedTab == 'Byta lösenord'">
                <Form :form="passwordForm" :item="passwordItem" :url="'user-password/'" :getItemFunction="updateEmailSettings" :inEditMode="true" :successMessage="'Härligt! Du har ett nytt lösenord!'"></Form>
            </div>
        </main>
    </div>
</template>
<style scoped>
h6{
    text-transform: uppercase;
    font-size: 13px;
    margin: 15px 0px 5px 0px;
}
</style>
