<script>
import AdminLink from '../components/AdminLink.vue'
import List from '../components/List/List.vue'
import PopUp from '../components/PopUp.vue'
import message from '@/helpers/message'
import BookingItem from '../components/BookingItem.vue'
import config from "@/helpers/config"
export default {
    components:{ AdminLink, List, PopUp, BookingItem},
    created() {   
        this.$cache.createCache('bookings','bookings/') ;  
        this.$cache.createCache('availableBookings','available-bookings/')
    },
    data() {
        return {    
            showBooking: null,      
            columns: [                                         
                { name: 'view', displayName: 'S', function: this.showInfo, icon: 'fa-solid fa-eye', align:'center', width: 30, hideFilter: true},
                { name: 'delete', displayName: 'T', isDelete: true, align:'center', width: 30, hideFilter: true, hideIfEdit: true},
                { name: 'booked_period', displayName: 'När', width: 250, hideFilter: false},              
                { name: 'name', displayName: 'Vad', width: 200, hideFilter: false},              
                { name: 'duration', displayName: 'Hur länge', width: 200, hideFilter: true},
                { name: 'comments', displayName: 'Anteckningar', width: 800, hideFilter: true},
              
            ], 
            sorting: [
                { name: 'formatted_start_date_and_time', displayName: 'Startdatum' },        
                { name: 'name', displayName: 'Vad som är bokat' }          
            ], 
            dropdowns: [{ prop: 'expired', value: false, availableItems: [{ id: false, name: 'Visa kommande' }, { id: true, name: 'Visa avslutade' }, { id: null, name: 'Visa alla' }] }]
        }       
    },
    methods:
    {
        delete(id)
        {
            var self = this;
            this.$communicator.post('bookings/' + id + '/delete')
                .then(function(data)
                {                 
                    self.getBookings();
                    message.setSuccessMessage();
                })
                .fail(function(error) {                  
                })  
        },
        book(booking)
       {
            if(booking.duration_unit == 'Dagar')
            {
                this.$router.push('/add-days-booking/' + booking.id); 
            }
            else{
                this.$router.push('/add-hours-booking/' + booking.id);                 
            }
       },
        getBookings()
        {
            this.editItem = null;
            this.$cache.updateCache('bookings', 'bookings/');
        },
        showInfo(item)
        {
            this.showBooking = item;
        },
        goToBookingList()
        {
            this.$router.push({ name: 'bookingList' })    
        }
    },
    computed: {
        bookings() {  return this.$store.state.bookings },
        url() { return config.urlToServer },
        customer() { return this.$store.state.customer; },
        availableBookings() { return this.$store.state.availableBookings != null ? this.$store.state.availableBookings.slice(0,3) : []; },
        bookingLabel() { return this.$store.state.settings.BookingItem.plural },
        bookingLabelsingular() { return this.$store.state.settings.BookingItem.singular },
    }
}
</script>
<template>
    <main class="template-two-cols-right-20-percent" v-fade-in v-if="bookings != null">
        <section>      
            <button class="float-right btn" style="margin-top: 10px" v-if="this.$isMobile" @click="goToBookingList">Ny {{bookingLabelsingular}}</button>      
            <h2>Mina {{bookingLabel }} &nbsp;&nbsp;</h2>
            <List 
                :items="this.$isMobile ? bookings.filter(x => !x.expired) : bookings" 
                :columns="columns" 
                :deleteFunction="delete" 
                :nameOfItems="bookingLabel" 
                :noOfFrozenColumns="this.$isMobile ? 1 : 2" 
                :hideSearch="this.$isMobile ? true : false" 
                :sorting="sorting"
                :hideExcelExport="true"
                :dropdowns="this.$isMobile ? null : dropdowns">
            </List>
            <div v-if="bookings.length == 0">
                Du har inga {{bookingLabel }}. Du är helt fri!
            </div>               
        </section>
        <section v-if="this.$isMobile && bookings.some(x => x.expired)">           
            <h2>Gamla {{bookingLabel}} &nbsp;&nbsp;</h2>
            <List 
                :deleteFunction="delete" 
                :items="bookings.filter(x => x.expired)" 
                :columns="columns" 
                :nameOfItems="bookingLabel" 
                :noOfFrozenColumns="3" 
                :hideSearch="true"
                :hideExcelExport="true"
                :sorting="sorting">
            </List>
            <div v-if="bookings.length == 0">
                Du har inga {{bookingLabel }}. Du är helt fri!
            </div>               
        </section>
        <aside v-if="!this.$isMobile">
            <h2>Populära {{bookingLabel }}</h2>
            <div v-for="booking in availableBookings" class="booking-item">  
                <BookingItem :booking="booking" :url="url" :customer="customer" :imgWidth="'100%'"></BookingItem>                               
            </div>
            <div v-if="availableBookings.length == 0">
                Det finns inga tillgängliga bokningsobjekt inlagda :(
            </div>
            <router-link to="booking-list">Visa alla tillgängliga {{ bookingLabel }}</router-link>
            <br><br>
            <AdminLink :buttonText="'Lägg till/ta bort bokningsbart'" :sectionToEdit="'adminBookings'"></AdminLink>        
        </aside>
    </main>
    <div v-if="showBooking">
            <PopUp>
                <button class="btn float-right" @click="showBooking = null">Stäng</button>
                <h2>Infomation om {{ showBooking.booking_item.name }}</h2>
                <div class="margin-top-15 text-left" v-html="showBooking.booking_item.information"></div>            
                <div class="info Bookings-info-box margin-top-15 text-left">
                    <b style="text-transform: uppercase;">Infomration om din {{bookingLabelsingular }}:</b>
                    <div class="margin-top-15" v-html="showBooking.booking_item.rules"></div>               
                </div>                          
                <div v-if="showBooking.booking_item.public_hidden_info != null" class="margin-top-15 text-left"><strong>Extra Information om bokningen:</strong> <span v-html="showBooking.booking_item.public_hidden_info"></span></div>            
            </PopUp>
        </div>
</template>
<style scoped>
    
    .Bookings-info-box
    {
        padding: 20px;
        background-color: #eeeab1;
    }

</style>