<script>
    export default {
        props: ['item', 'prop', 'selectItems', 'title'],
        data() {
            return {
               
            }
        },
        methods: {
            selectItem(item) {
                const index = this.item[this.prop].indexOf(item.id);
                if (index === -1) {
                    this.item[this.prop].push(item.id);  // add
                } else {
                    this.item[this.prop].splice(index, 1); // remove
                }
            }
           
        },
        computed:
        {
            selected_ids() {return this.item[this.prop] }
        }
    }
</script>

<template>
    <div class="row">
        <div class="g-100">   
            <button v-for="item in selectItems" class="btn" @click="selectItem(item)" :class="!selected_ids.includes(item.id) ? 'btn-gray': ''">
                {{ item.name }}
            </button>          
        </div>        
    </div>
</template>
    
<style scoped>

</style>