<script>
import List from '@/components/List/List.vue'
import Form from '@/components/Form/Form.vue'
import PopUp from '@/components/PopUp.vue'
import UploadExcel from '@/components/Excel/UploadExcel.vue'
import YesNoPopUp from '@/components/YesNoPopUp.vue';
import ToAdmin from '@/components/ToAdmin.vue'
import message from '@/helpers/message'
import UserInstruction from '@/components/UserInstruction.vue'
import Loading from '@/components/Loading.vue'
import lookups from '@/helpers/lookups'
export default {
    components:{ List, Form, PopUp, ToAdmin, UserInstruction, Loading, UploadExcel, YesNoPopUp },
    created() {        
        if(this.user.user_level != 'SamfallifyCrew' && this.user.user_level != 'Redaktör')
        {
            this.$router.push('/login');
        } 

        this.$cache.createCache('members', 'members/');
    },
    data() {
        return { 
            file: null,
            uploadType: { 
                tableHeaders: ['Förnamn', 'Efternamn', 'Email', 'Användarnivå'], 
                isMemberUpload: true, 
                importButtonText: 'Importera Medlemslista',
                url: 'upload-members',
                successMessage: 'Toppen! Medlemmarna är nu tillagda!'
             },                    
            editItem: null,
            isAdding: false,
            sendingEmails: false,
            send_welcome_emails_to_all: false,
            form: [   
                    { sections: [{ sectionName: '', classes: "g-100", inputs: [ 
                             { type: 'infoText', title: '', infoText: 'Här anger du namn och email till medlemmen som ska få tillgång till jamii. Välj sedan om du vill skicka ett välkomstmail nu eller göra det senare från medlemslistan.', classes: "g-100" },                        
                            ]
                        }]
                    },                
                    { sections: [{ sectionName: 'Namn', classes: "g-100", inputs: [ 
                             { type: 'text', prop: 'first_name', title: 'Förnamn', classes: "g-50", required: true },
                             { type: 'text', prop: 'last_name', title: 'Efternamn', classes: "g-50", required: true },
                            ]
                        }]
                    },
                    { sections: [{ sectionName: 'Email', classes: "g-100", inputs: [
                            { type: 'text', prop: 'email', title: 'Email', classes: "g-100", required: true }
                            ]
                        }]
                    },
                    { sections: [{ sectionName: 'Användarnivå', classes: "g-100", inputs: [
                            { type: 'select', prop: 'user_level', title: 'Användarnivå', classes: "g-100", required: true }
                            ]
                        }]
                    }
                ],
            formForUserNotUsingJamii: [   
                    { sections: [{ sectionName: '', classes: "g-100", inputs: [ 
                             { type: 'infoText', title: '', infoText: 'Här anger du för- och efternamn på medlemmen som INTE ska få tillgång till jamii. Övrig information går att mata in när du klickar på "Redigera" i medlemslistan. ', classes: "g-100" },                        
                            ]
                        }]
                    },                
                    { sections: [{ sectionName: 'Namn', classes: "g-100", inputs: [ 
                             { type: 'text', prop: 'first_name', title: 'Förnamn', classes: "g-50", required: true },
                             { type: 'text', prop: 'last_name', title: 'Efternamn', classes: "g-50", required: true },
                            ]
                        }]
                    }                    
                ],
                
                   
        }
    },   
    methods:
    {
        edit(item)
        {
            this.isAdding = false;          
            this.editItem = item;
            
        },
        add()
        {
            this.isAdding = true;
            this.editItem = { id: 0, first_name: '', last_name: '', email: '', has_recieved_email: false, will_not_use_jamii: false, decide_if_using_jamii: false };
        },
        send_welcome_email(item)
        {
            var self = this;
            this.$communicator.get('send-welcome-email-to-user/' + item.user_id)
                .then(function(data)
                {                 
                    self.getMembers();
                    message.setSuccessMessage('Härligt! Användaren har nu fått ett välkomstmail med användarnamn och lösenord');
                })
                .fail(function(error) {                  
                })  
        },
        send_welcome_emails()
        {
            var self = this;
            this.sendingEmails = true;
            this.$communicator.get('send-welcome-emails-to-users/')
                .then(function(data)
                {                 
                    self.getMembers();
                    self.send_welcome_emails_to_all = false;
                    message.setSuccessMessage('Härligt! Användarna har nu fått var sitt välkomstmail med användarnamn och lösenord');
                    
                })
                .fail(function(error) { 
                    self.sendingEmails = false;                 
                })  
        },
        delete(id)
        {
            var self = this;
            this.$communicator.post('members/' + id + '/delete')
                .then(function(data)
                {                 
                    self.getMembers();
                    message.setSuccessMessage();
                })
                .fail(function(error) {                  
                })  
        },
        getMembers()
        {
            this.editItem = null;
            this.$cache.updateCache('members', 'members/');
            lookups.getLookups();  
            self.sendingEmails = false; 
        },  
        selectMemberActivityInJamii(willNotUseJamii)
        {
            this.editItem.will_not_use_jamii = willNotUseJamii;
            this.editItem.decide_if_using_jamii = true;
        }          
    },
    computed:
    {
        user() {return this.$store.state.user; },
        members() {  
            var self = this;
            return this.$store.state.members != undefined ? this.$store.state.members.map(x => (
                {
                    id: x.id, 
                    created_date: this.$formatters.date(x.created_date, 'date'), 
                    first_name: x.first_name,
                    last_name: x.last_name, 
                    has_recieved_email: x.has_recieved_email, 
                    has_not_recieved_email: !x.has_recieved_email && !x.will_not_use_jamii, 
                    user_level: x.user_level,     
                    user_id: x.id,
                    email: x.email,
                    is_active: x.is_active,
                    age: this.$formatters.age(x.birthdate),
                    birthdate: x.birthdate,
                    address_street: x.address_street,
                    address_number: x.address_number,
                    address_postal: x.address_postal,
                    address_city: x.address_city,
                    address: this.$formatters.address(x),
                    phone_number: x.phone_number,
                    gender: x.gender,
                    property_designation: x.property_designation,
                    will_not_use_jamii: x.will_not_use_jamii
                })) : []
        },
        columns() { 
            var cols =  [                             
                { name: 'edit', displayName: '<i class="fa-solid fa-pen color-black"></i>', icon: 'fa-solid fa-pencil', align:'center', title: 'Redigera', function: this.edit, width: 25, hideFilter: true, hideIfEdit: true},
                { name: 'delete', displayName: '<i class="fa-solid fa-trash color-black"></i>', isDelete: true, align:'center', width: 25, title: 'Ta bort', hideFilter: true, hideIfEdit: true},
                { name: 'send', displayName: '<i class="fa-solid fa-message color-black"></i>', isSendUserMessage: true, align: 'center',  title: 'Skicka meddelande', width: 25, hideFilter: true, hideIfEdit: true},         
                { name: 'first_name', displayName: 'Förnamn', width: 120, hideFilter: false},
                { name: 'last_name', displayName: 'Efternamn', width: 200, hideFilter: true},
                { name: 'will_not_use_jamii', displayName: '<i class="fa-solid fa-user-slash color-black"></i>', isBoolean: true, align:'center', title: 'Medlemmen använder inte Jamii', excelName: 'Medlemmen använder inte Jamii', width: 25, hideFilter: true},
                { name: 'has_recieved_email', displayName: 'Har fått välkomstmail', width: 170, hideFilter: true, isBoolean: true, align: 'center'},
                { name: 'has_not_recieved_email', displayName: '<i class="fa-solid fa-paper-plane color-black"></i>', icon: 'fa-solid fa-paper-plane', align:'center', title: 'Skicka välkomstmail till medlemmen', onlyShowIfTrue: true, function: this.send_welcome_email, width: 25, hideFilter: true, hideIfEdit: true},
                { name: 'is_active', displayName: 'Aktiv', width: 80, hideFilter: true, isBoolean: true, align: 'center'},
                { name: 'user_level', displayName: 'Användarnivå', width: 130, hideFilter: true},
                { name: 'phone_number', displayName: 'Telefonnummer', width: 200, hideFilter: true},
                { name: 'email', displayName: 'Email', width: 200, hideFilter: true},
                { name: 'address', displayName: 'Adress', width: 400, hideFilter: true},                                     
                { name: 'created_date', displayName: 'Skapad', width: 100, hideFilter: true}
            ]
            
            if(this.settings.UserSetting_form_property_designation.enabled)
            {
                cols.splice(11,0,{ name: 'property_designation', displayName: 'Fastighetsbeteckning', width: 200, hideFilter: true});

            }

            return cols;
        },
        sorting() { 
            var sorting = [
                { name: 'first_name', displayName: 'Förnamn' },      
                { name: 'last_name', displayName: 'Efternamn' },      
                { name: 'user_level', displayName: 'Användarnivå' },      
                { name: 'address', displayName: 'Adress' },      
                    
            ]  

            if(this.settings.UserSetting_form_property_designation.enabled)
            {
                sorting.push( { name: 'property_designation', displayName: 'Fastighetsbeteckning' });

            }

            return sorting;
        } ,
        settings() {
           return this.$store.state.settings;
        },
        editForm() {
            return [
                { sections: [
                    { sectionName: 'Namn, e-mail och telefon', classes: "g-50", inputs: [
                        { type: 'text', prop: 'first_name', title: 'Förnamn', classes: "g-50", required: true }, 
                        { type: 'text', prop: 'last_name', title: 'Efternamn', classes: "g-50", required: true },
                        { type: 'text', prop: 'email', title: 'E-mail', classes: "g-100", required: this.editItem != null && !this.editItem.will_not_use_jamii },
                        { type: 'text', prop: 'phone_number', title: 'Telefonnummer', classes: "g-100" }
                    ] },
                    { sectionName: 'Adress', classes: "g-50", inputs: [
                        { type: 'text', prop: 'address_street', title: 'Gata', classes: "g-80" }, 
                        { type: 'text', prop: 'address_number', title: 'Gatnr.', classes: "g-20" },
                        { type: 'text', prop: 'address_postal', title: 'Postnr.', classes: "g-30" },
                        { type: 'text', prop: 'address_city', title: 'Postort', classes: "g-70" }
                        ]
                    }, { sectionName: 'Fastighetsbeteckning', disabled: !this.settings.UserSetting_form_property_designation.enabled, classes: "g-50", inputs: [
                        { type: 'text', prop: 'property_designation', title: '', classes: "g-100" }, 
                    
                        ]
                    },{ sectionName: 'Aktiv i Jamii', classes: "g-50", disabled: !this.editItem.will_not_use_jamii, inputs: [
                        { type: 'switch', prop: 'will_not_use_jamii', title: 'Medlemmen kommer aldrig att använda Jamii', classes: "g-100" }, 
                        
                        ]
                    }, { sectionName: 'Användarnivå', classes: "g-50", disabled: this.editItem.will_not_use_jamii, inputs: [
                        { type: 'select', prop: 'user_level', title: 'Användarnivå', classes: "g-100" }, 
                        
                    ]
                }]
                }] 
        },       
    }
}
</script>
<template>
    <div v-if="settings.IsUserSettings" v-fade-in>
        <main class="opacity-admin-bg overflow-auto position-relative">
            <div class="float-right">
                <button id="List-sort-btn" class="btn" @click="add">Lägg till en medlem</button>
                <button id="List-sort-btn" v-if="members.some(x => x.has_not_recieved_email)" class="btn btn-gray" @click="send_welcome_emails_to_all = true" v-tooltip="'Skicka välkomstmail till alla som ännu inte fått något.'" :disabled="sendingEmails">Skicka välkomstmail</button>
                <UploadExcel :type="uploadType"></UploadExcel>
                <UserInstruction :page="this.$route.name"></UserInstruction>
            </div>
            <ToAdmin :section="'Medlemmar'"></ToAdmin>
            <h2>Medlemmar</h2>
           
                <div v-if="editItem != null">
                    <PopUp>
                        <div v-if="isAdding">
                            <div v-if="!editItem.decide_if_using_jamii">
                                <h2>Hej Redaktör</h2>
                                Först behöver du bestämma om medlemmen ska aktivt använda Jamii eller inte. 
                                I vissa fall kan det vara så att medlemmen inte kan/vill använda Jamii men behöver matas in för att få en komplett medlemslista.
                                <br><br>
                                <div style="display: flex; justify-content: center">
                                    <button class="btn btn-gray" @click="editItem = null">Avbryt</button>
                                    <button class="btn" @click="selectMemberActivityInJamii(false)">Medlemmen kommer använda Jamii</button>
                                    <button class="btn btn-gray" @click="selectMemberActivityInJamii(true)">Medlemmen kommer INTE använda Jamii</button>
                                </div>
                                <br><br>
                            </div>
                            <div v-else>
                                <div v-if="editItem.will_not_use_jamii">
                                    <Form :form="formForUserNotUsingJamii" :item="editItem" :url="'users-not-using-jamii/'" :getItemFunction="getMembers" :inEditMode="true" :successMessage="'Användaren är sparad i systemet.'"></Form>
                                </div>
                                <div v-else>
                                    <Form :form="form" :item="editItem" :url="'users/'" :getItemFunction="getMembers" :inEditMode="true" :saveText="'Spara och skicka välkomstmail senare'" :isAddingMember="true" :successMessage="'Användaren är sparad och ett mail har skickat ut med användarnamn och lösenord.'"></Form>
                                </div>
                            </div>
                        </div>
                        <div v-if="!isAdding">
                            <Form :form="editForm" :item="editItem" :url="'users/'" :getItemFunction="getMembers" :inEditMode="true" :saveText="'Spara'"></Form>
                        </div>
                    </PopUp>
                </div>
               
            <List :items="members" :columns="columns" :noOfFrozenColumns="5" :deleteFunction="delete" :isEditing="editItem != null" :nameOfItems="'medlemmar'" :sorting="sorting" :backgroundColor="this.$colors.list.backgroundColor" :borderColor="this.$colors.list.borderColor"></List>        
            <!-- <Loading></Loading> -->
        </main>
        <div v-if="send_welcome_emails_to_all">
        <YesNoPopUp :yes="send_welcome_emails" :no="() => send_welcome_emails_to_all = false" :text="'Du kommer skicka ut välkomstmail till alla medlemmar som ännu inte fått något email. Klicka JA om du vill fortsätta'"></YesNoPopUp>       
    </div>
    </div>  
</template>
<style scoped>
    .AdminNotifications-edit-panel
    {
        padding:10px;
        margin: 15px 0px;
        border:1px solid #ddd;
    }
</style>