<script>
import List from '@/components/List/List.vue'
import FormNoSave from '@/components/Form/FormNoSave.vue'
import ToAdmin from '@/components/ToAdmin.vue'
import UserInstruction from '@/components/UserInstruction.vue'
import PopUp from '@/components/PopUp.vue'
import CalendarDays from '@/components/Calendar/CalendarDays.vue'

export default {
    components:{ List, FormNoSave,ToAdmin, UserInstruction, PopUp, CalendarDays },
    data() {
        return {             
            sorting: [
                { name: 'name', displayName: 'Namm' },           
                { name: 'creaded_date', displayName: 'Skapad' },           
                { name: 'creaded_by', displayName: 'Skapad Av' },           
            ],
            editItem: null,
            isAdding: false,
            occations: [],             
            selectedOccation: null,
            availableInstructors: [],              
            availableLocations: null,  
            selectedDay: { day: null, date: null, isBlocked: false}            
        }
    },   
    created() {  
        if(this.user.user_level != 'SamfallifyCrew' && this.user.user_level != 'Redaktör')
        {
            this.$router.push('/login');
        } 
        this.$cache.createCache('accountSettings', 'account-settings/') 
    
        var id = this.$route.params.id == undefined ? 0 : this.$route.params.id;
        var self = this;
        this.isAdding = id == 0;
        this.$communicator.get('admin/courses/' + id)
        .then(function(data)
        {             
            self.availableLocations = data.course_locations;
            self.editItem = data.course;
            self.occations = data.course_occations;
            self.availableInstructors = data.course_instructors;
        })
        .fail(function(error) {
            
        });          
    },
    methods:
    {  
                
    },
    computed:
    {
        user() {return this.$store.state.user; },
        accountSettings() {return this.$store.state.accountSettings; },        
        form() {
            var form = [                   
                { sections: [{ sectionName: 'Namn och information', classes: "g-100", inputs: [ 
                    { type: 'text', prop: 'name', title: 'Namn', classes: "g-100", required: true },
                    { type: 'textEditor', prop: 'description', title: 'Information', classes: "g-100", required: true },                          
                            { type: 'number', prop: 'max_number_of_participents', title: 'Antal deltagare', classes: "g-50", required: true },
                            { type: 'number', prop: 'price', title: 'Pris', classes: "g-50", required: true },
                        ]
                    },
                    { sectionName: 'Lägg till bild', classes: "g-100", inputs: [
                        { type: 'image', prop: 'image_id', title: 'Klicka på den bild du vill använda som kursbild', classes: "g-100" },                        
                        ]
                    },     
                    ],
                        backgroundColor: this.$colors.form.backgroundColor,
                        borderColor: this.$colors.form.borderColor
                }];
            
            if(this.$store.state.accountSettings != null && this.$store.state.accountSettings.enable_public_page)
            {
                form[0].sections[0].inputs.push({ type: 'select', prop: 'is_public_course', title: 'Gör denna kurs synlig på den publika sidan', classes: "g-100", selectItems: 'yesno' });               
            }

            return form;
        },
        availableOccations() {
            var list = [];
            for(var i = 1; i <= 30; i++)
            {
                list.push({id: i, name: i.toString(), display: true, selected: false });
            }

            return list;
        },     
        form2() {
           return [                   
                { sections: [{ sectionName: 'Lokal och kursansvariga', classes: "g-100", inputs: [                     
                            { type: 'select', prop: 'course_location_id', title: 'Lokal', classes: "g-100", selectItems: this.availableLocations, required: true },
                            { type: 'multiSelectBtns', prop: 'owner_ids', title: 'Välj kursansvariga', required: true, selectItems: this.availableInstructors, classes: "g-100" },
                            
                        ]
                    },
                    { sectionName: 'Kursstart och antal tillfällen', classes: "g-100", inputs: [                     
                            { type: 'date', prop: 'course_start', title: 'Kursstart', classes: "g-50", required: true },
                            { type: 'date', prop: 'last_day_of_application', title: 'Sista anmälningsdagen', classes: "g-50", required: true },
                            { type: 'select', prop: 'number_of_occations', title: 'Antal tillfällen', classes: "g-100", required: true, selectItems: this.availableOccations },                          
                        ]
                    }          
                    ],
                        backgroundColor: this.$colors.form.backgroundColor,
                        borderColor: this.$colors.form.borderColor
                }]

        },

        
    },
    watch:
    {
        editItem: {
            handler(editItem){
                if(editItem != null && editItem.course_start && editItem.number_of_occations && editItem.last_day_of_application)
                {
                    var number_of_occations = parseInt(editItem.number_of_occations);
                    if(this.isAdding)
                    {
                        this.occations = [];
                        for(var i = 0; i < number_of_occations; i++)
                        {
                            this.occations.push({id: i, start_date: null, end_time: null, description: null })
                        }
                    }
                    else {
                        if(this.occations.length < number_of_occations)
                        {
                            for(var i = this.occations.length; i < number_of_occations; i++)
                            {
                                this.occations.push({id: i, start_date: null, end_time: null, description: null })
                            }
                        }
                        else if(this.occations.length > number_of_occations)
                        {
                            this.occations.splice(number_of_occations);
                        }

                    }
                }
            },
            deep: true
        }
    }
}
</script>
<template>
    <div v-fade-in v-if="accountSettings != null && availableOccations.length > 0 && availableLocations">
        <main class="opacity-admin-bg overflow-auto position-relative">
            <div class="breadcrumbs">
                <router-link :to="{ name: 'admin' }">Startsidan: Redaktör</router-link>
                &nbsp; >>  &nbsp;
                <router-link :to="{ name: 'adminCourses' }">Kurser</router-link>
                &nbsp; >>  &nbsp;
                <b v-if="isAdding">Lägg till en kurs</b>
                <b v-else>Redigera en kurs</b>
            </div>
            <div class="float-right">              
                <UserInstruction :page="this.$route.name"></UserInstruction>
            </div>
            <h2 v-if="isAdding">Lägg till en kurs</h2>
            <h2 v-if="!isAdding">Redigera en kurs</h2>
            <div class="overflow-auto">
                <div v-if="editItem != null" class="g-50">
                    <FormNoSave :form="form" :item="editItem" :isEditing="true" :hideMandatoryInformation="true"></FormNoSave>
                </div>            
                <div v-if="editItem != null" class="g-50">
                    <FormNoSave :form="form2" :item="editItem" :isEditing="true" :hideMandatoryInformation="true"></FormNoSave>
                    <fieldset v-if="editItem != null && editItem.course_start && editItem.number_of_occations && editItem.last_day_of_application" class="AdminCourseEdit-occations">
                        <legend>Kurstillfällen</legend>
                        <div v-for="(occation,index) in occations" class="AdminCourseEdit-occation overflow-auto">
                            Kurstillfälle {{ index+1 }}: 
                            <span v-if="occation.start_date">
                                <button class="btn btn-gray float-right" style="margin:-8px 0px 8px 0px"  @click="selectedOccation = occation">Ändra i kalendern</button> 
                            {{ occation.occation_duration }}  
                            </span>
                            <span v-else>
                                &nbsp;<button class="btn" style="margin: -8px 0px 8px;">Välj i kalendern</button>  
                            </span>
                        </div>
                    </fieldset>         
                </div>
            </div>
            <div class="g-100">
                <div class="btn float-right ">Spara</div>
                <div class="btn btn-gray float-right" @click="cancel">Avbryt</div>
            </div>   
        </main>
        <PopUp v-if="selectedOccation" :width="'90%'" :bgColor="this.$colors.list.backgroundColor">
            <div class="g-70">
            <CalendarDays :bookings="[]" :blockings="[]" :selectedDay="selectedDay"></CalendarDays>
            </div>
            <div class="g-30">
                {{ selectedDay }}
            </div>
    </PopUp> 
    </div>
</template>
<style scoped>
    .AdminCourseEdit-occations {
        border: 1px solid rgb(196, 170, 116);
        background: rgb(240, 224, 190);
        margin: 5px;
        padding: 14px;
    }

    .AdminCourseEdit-occation {
        border-bottom: 1px dashed  rgb(196, 170, 116);
        padding: 14px 0px 0px 0px;
    }

    legend{
        font-weight: 600;
        font-size: 13px;
        text-align: left;
        text-transform: uppercase;
    }
</style>