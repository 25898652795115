<script>
    export default {
        props: ['filterHeight','items','prop','id','filters', 'isNumber'],
        data() {
            return {
                availableFilterItems: [],
                displayFilterMenu: false,
                displayFilterIcon: false,
                top: '0px',
                right: '0px'
            }
        },
        computed: {
                    
        },
        created() {
            var self = this;
            // Create filter items based on data source.
            var filterItems = this.items
                .flatMap(p => (p[this.prop] != null && p[this.prop] != undefined ? p[this.prop] : '')
                    .toString()
                    .split('|')
                    .map(t => t.trim()));
                        //.filter(f => f)); // remove empty items
            
            var uniqueFilterItems = [...new Set(filterItems)].sort();

            for (var i = 0; i < uniqueFilterItems.length; i++) {
                this.availableFilterItems.push({ Name: uniqueFilterItems[i], Selected: false });
            }

            //when click outside list
            $(document).mouseup(function (e) {
                var container = $("#ListFilter-" + self.prop + self.id);

                // if the target of the click isn't the container nor a descendant of the container
                if (!container.is(e.target) && container.has(e.target).length === 0) {
                    container.appendTo('#ListFilter-menu-' + self.prop + self.id);
                    self.displayFilterMenu = false;
                }
            });
        },
        watch: {
            items() {
                var filterItems = this.items
                        .flatMap(p => (p[this.prop] != null && p[this.prop] != undefined ? p[this.prop] : '')
                            .toString()
                            .split('|')
                            .map(t => t.trim())
                            .filter(f => f)); // remove empty items
                
                var uniqueFilterItems = [...new Set(filterItems)].sort();

                for (var i = 0; i < uniqueFilterItems.length; i++) {
                    if (!this.availableFilterItems.some(p => p.Name == uniqueFilterItems[i])) {
                        this.availableFilterItems.push({ Name: uniqueFilterItems[i], Selected: false });
                    }
                }
                //TODO om man tar bort en filter item s� ska den f�rsvinna
            }
        },
        methods: {
            openFilterMenu() {
                this.cancel();
                this.displayFilterMenu = !this.displayFilterMenu;
                $("#ListFilter-" + this.prop + this.id).appendTo("body");
                var elmPosition = document.getElementById('ListFilter-menu-' + this.prop + this.id).getBoundingClientRect();
                this.right = document.body.clientWidth + window.scrollX - elmPosition.right + "px";
                this.top = elmPosition.top + window.scrollY + 30 + "px";
            },
            updateFilter(unselectAll) {
                for (var i = 0; i < this.availableFilterItems.length; i++) {
                    var item = this.availableFilterItems[i];

                    //do if clear btn has been clicked
                    if (unselectAll) { item.Selected = false }

                    if (item.Selected) {
                        if (this.filters[this.prop]) {
                            this.filters[this.prop].push(item.Name);
                        }
                        else {
                            this.filters[this.prop] = [item.Name];
                        }
                    }
                    else {
                        if (this.filters[this.prop]) {
                            this.filters[this.prop] = this.filters[this.prop].filter(function (ele) {
                                return ele !== item.Name;
                            });
                        }
                    }
                }

                this.displayFilterIcon = this.filters[this.prop] && this.filters[this.prop].length > 0;
                this.displayFilterMenu = false;
                this.$emit('doFilterList');
            },
            cancel() {

                for (var i = 0; i < this.availableFilterItems.length; i++) {
                    var item = this.availableFilterItems[i];
                    var valuesInFilter = this.filters[this.prop];

                    if (valuesInFilter === undefined) {
                        item.Selected = false;
                    } else if (valuesInFilter.includes(item.Name)) {
                        item.Selected = true;
                    } else {
                        item.Selected = false;
                    }
                }

                this.displayFilterIcon = this.filters[this.prop] && this.filters[this.prop].length > 0;
                $("#ListFilter-" + this.prop + this.id).appendTo('#ListFilter-menu-' + this.prop + this.id);
                this.displayFilterMenu = false;
            }
        }
    }
</script>

<template>
    <div class="ListFilter-menu" :id="'ListFilter-menu-' + prop + id">
        <div @click="openFilterMenu">
            <div v-show="!displayFilterIcon" style="padding:7px 6px 0px 8px">
                <i class="fa fa-caret-down"></i>
            </div>
            <div  v-show="displayFilterIcon" style="font-size:7pt; padding:10px 9px 1px 0px;">
                <i class="fa fa-filter"></i>
            </div>
        </div>
        <div class="ListFilter-filter-list" v-show="displayFilterMenu" :id="'ListFilter-' + prop + id" :style="{ 'right': right, 'top': top}">
            <ul :style="{ 'max-height': filterHeight + 'px' }">
                <li v-for="item in availableFilterItems">
                    <label class="ListFilter-label">
                        <input type="checkbox" class="ListFilter-checkbox" v-model="item.Selected"/> &nbsp; {{item.Name == '' ? '[none]' : item.Name}}
                    </label>
                </li>
            </ul>
            <div class="ListFilter-btns">
                <button class="btn btn-info btn-sm" @click="updateFilter(false)" style="width: 67px;"><i class="fa-solid fa-check"></i> &nbsp;OK</button>
                <button class="btn btn-info btn-sm" @click="updateFilter(true)"><i class="fa-solid fa-eraser"></i> &nbsp;Rensa</button> 
                <button class="btn btn-default btn-sm" @click="cancel" style="margin-right:0px;"><i class="fa-solid fa-ban"></i> &nbsp;Avbryt</button>
            </div>
        </div>
    </div>
</template>

<style scoped>
    .ListFilter-menu {
        float: right;
        margin-top: -8px;
        cursor: pointer;
        position: relative;
    }

    .ListFilter-checkbox
    {
        width: auto;
    }

    .ListFilter-filter-list {
        position: absolute;
        background-color: white;
        border: 1px solid #ddd;
        font-family: OpenSansNormal, "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif;
        font-weight: 400;
        z-index: 4000;
        padding-bottom: 4px;
    }

        .ListFilter-filter-list ul {
            list-style: none;
            overflow: auto;
            margin: 4px;
            padding: 12px;
            width: 214px;
            border: 1px solid #ddd;
        }

        .ListFilter-filter-list li {
            font-size: 11px;
        }

        .ListFilter-filter-list label{
            font-size: 12px;
            line-height: 22px;
        }

    .ListFilter-btns {
        padding: 0px 5px;
    }
    
    .ListFilter-btns button {
        margin-right: 4px;
        padding: 4px 12px;
    }

    .ListFilter-label {
        font-family: OpenSansNormal, "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif;
        font-weight: 400;
    }
</style>
