<script>
import List from '@/components/List/List.vue'
import Form from '@/components/Form/Form.vue'
import PopUp from '@/components/PopUp.vue'
import ToAdmin from '@/components/ToAdmin.vue'
import UploadExcel from '@/components/Excel/UploadExcel.vue'
import message from '@/helpers/message'
import UserInstruction from '@/components/UserInstruction.vue'
export default {
    components:{ List, Form, PopUp, ToAdmin, UserInstruction, UploadExcel },
    created() {  
        if(this.user.user_level != 'SamfallifyCrew' && this.user.user_level != 'Redaktör')
        {
            this.$router.push('/login');
        } 
        
        this.$cache.createCache('rulesOfProcedure', 'rules-of-procedure/');
    },
    data() {
        return {  
            uploadType: { 
                tableHeaders: ['Ordningsregel'], 
                isRulesOfProcedureUpload: true, 
                importButtonText: 'Importera Ordningsregler',
                successMessage: 'Härligt, dina ordningsregler är nu tillagda i jamii.', 
                url: 'upload-rules-of-procedure'
            }, 
            columns: [                             
                { name: 'edit', displayName: '<i class="fa-solid fa-pencil color-black"></i>', title:'Redigera', icon: 'fa-solid fa-pencil', align:'center', function: this.edit, width: 25, hideFilter: true, hideIfEdit: true},
                { name: 'delete', displayName: '<i class="fa-solid fa-trash color-black"></i>',  title: 'Ta bort', isDelete: true, align:'center', width: 25, hideFilter: true, hideIfEdit: true},                
                { name: 'rule_of_procedure', displayName: 'Ordningsregel', width: 300, hideFilter: true, isTextEditorFormat: true},
                { name: 'sort_order', displayName: 'Sorteringsordning', width: 130, hideFilter: true, align: 'center' },
                { name: 'created_date', displayName: 'Skapad', width: 100, hideFilter: true},
                { name: 'created_by', displayName: 'Skapad Av', width: 200},
              
            ], 
            sorting: [
                { name: 'rule_of_procedure', displayName: 'Ordningsregel' },           
                { name: 'sort_order', displayName: 'Sorteringsordning' },           
                { name: 'created_date', displayName: 'Skapad' },           
                { name: 'created_by', displayName: 'Skapad Av' }           
            ],
            editItem: null,
            form: [                   
                    { sections: [
                        { sectionName: 'Lägg till en ordningsregel', classes: "g-100", inputs: [
                            { type: 'textEditor', prop: 'rule_of_procedure', title: 'ordningsregel', classes: "g-100", required: true }, 
                            { type: 'number', prop: 'sort_order', title: 'Sorteringsordning', classes: "g-100" }, 
                            ]
                        }]
                    }],
        }
    },   
    methods:
    {
        edit(item)
        {
            this.editItem = item;
        },
        add()
        {
            this.editItem = { id: 0, rule_of_procedure: ''};
        },
        delete(id)
        {
            var self = this;
            this.$communicator.post('rules-of-procedure/' + id + '/delete')
                .then(function(data)
                {                 
                    self.getRulesOfProcedure();
                    message.setSuccessMessage();
                })
                .fail(function(error) {                  
                })  
        },
        getRulesOfProcedure()
        {
            this.editItem = null;
            this.$cache.updateCache('rulesOfProcedure', 'rules-of-procedure/');
        }
    },
    computed:
    {
        user() {return this.$store.state.user; },
        rulesOfProcedure() {  
            return this.$store.state.rulesOfProcedure != undefined ? this.$store.state.rulesOfProcedure.map(x => (
                {
                    id: x.id, 
                    created_date: this.$formatters.date(x.created_date, 'date'), 
                    rule_of_procedure: x.rule_of_procedure,
                    created_by: x.created_by_user.full_name,
                    sort_order: x.sort_order
                })) : []
            }        
    }
}
</script>
<template>
    <div v-fade-in>
        <main class="opacity-admin-bg overflow-auto position-relative">
            <div class="float-right">
                <button id="List-sort-btn" class="btn" @click="add">Lägg till en ordingsregel</button>
                <UploadExcel :type="uploadType"></UploadExcel>
                <UserInstruction :page="this.$route.name"></UserInstruction>
            </div>
            <ToAdmin :section="'Ordningsregler'"></ToAdmin>
            <h2>Ordningsregler</h2>     
                <div v-if="editItem != null">
                    <PopUp>
                        <Form :form="form" :item="editItem" :url="'rules-of-procedure/'" :getItemFunction="getRulesOfProcedure" :inEditMode="true"></Form>
                    </PopUp>
                </div>
            <List :items="rulesOfProcedure" :columns="columns" :noOfFrozenColumns="3" :deleteFunction="delete" :isEditing="editItem != null" :nameOfItems="'ordningsregler'" :sorting="sorting" :backgroundColor="this.$colors.list.backgroundColor" :borderColor="this.$colors.list.borderColor"></List>        
            <div v-if="rulesOfProcedure.length == 0">
            Inga ordningsregler har lagts till.</div>
        </main>
    </div>
</template>
<style scoped>
    .AdminNotifications-edit-panel
    {
        padding:10px;
        margin: 15px 0px;
        border:1px solid #ddd;
    }
</style>