<script>
import config from "@/helpers/config"
export default {
    props: ['selectedImage', 'images'],   
    data(){
        return {      
            value: 0,
            showNextBtn: true,
            pixelsToAdd: this.$isMobile ? 300 : 500,
        }
    },
    created() {
        var self = this;
        setTimeout(() => {
            var width = self.getImagesWidth(this.images.indexOf(this.selectedImage.image));
            self.value = (self.fullWidth/2)-width;
        }, 300);
    },
    methods:
    {
        slide(isRight) {
           var pixels = isRight ? this.pixelsToAdd : -this.pixelsToAdd;     
           this.value = this.value - pixels;      
           var width = this.getImagesWidth();

           this.showNextBtn = Math.abs(this.value) < (width - this.fullWidth);    
        },     
   
        getImagesWidth(onlyWidthToSelectedIndex) {
            var width = 0;
            $(".ImageGalleryPopUp-thumbnails").each(function(index, item) {   
                width = width + item.offsetWidth;
                
                if(index == onlyWidthToSelectedIndex) 
                    return false;
            })

            return width;
        }
    },
    computed: {
            url() { return config.urlToServer }, 
            fullHeight() { return $(window).height() + 'px'  },  
            fullWidth() { return $(window).width() },  
            imgAreaHeight() {return $(window).height() - 150 + 'px' }
        }
}
</script>
<template>
    <div class="ImageGalleryPopUp-bg" v-fade-in="200" :style="{ 'height': fullHeight }" v-if="selectedImage.image != null">
        <button class="btn ImageGalleryPopUp-close-btn" @click="selectedImage.image = null">Stäng</button>
        <div class="ImageGalleryPopUp-img-area" :style="{ 'height': imgAreaHeight }">
            <div  v-for="image in images">       
                <img :src="url + 'uploads/'+  image.customer_id+'/images/'+ image.name" :style="{ 'max-height': imgAreaHeight }" v-if="image.id == selectedImage.image.id"  v-fade-in />  
            </div>

        </div>
        <div class="ImageGalleryPopUp-menu-btn left" v-show="value < 0" @click="slide(false)"><i class="fa-regular fa-circle-left"></i></div>
        <div id="ImageGalleryPopUp-menu" :style="{ 'transform': 'translateX('+ value +'px)' }">     
                <img v-for="(image, index) in images" class="ImageGalleryPopUp-thumbnails" :src="url + 'uploads/'+  image.customer_id+'/images/thumbnails/'+ image.name" @click="selectedImage.image = image" :style="{ 'filter': selectedImage.image.id == image.id ? 'grayscale(0%)' : ''}" v-fade-in />              
        </div>
        <div class="ImageGalleryPopUp-menu-btn right" v-show="showNextBtn" @click="slide(true)"><i class="fa-regular fa-circle-right"></i> </div>
    </div>
</template>

<style scoped>

.ImageGalleryPopUp-menu-btn
{
    position: absolute;
    padding: 50px 10px;    
    height: 50px;
    font-weight: bold;
    background: rgb(0,0,0);
    z-index: 1;
    bottom: 0px;
}

.left
{
    left: 0px;
    background: linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(59,59,59,0) 100%);
}

.right
{
    right: 0px; 
    background: linear-gradient(270deg, rgba(0,0,0,1) 0%, rgba(59,59,59,0) 100%);
}

i {
    color: wheat;
    font-size: 52px;
}

.ImageGalleryPopUp-bg
{
    position: fixed;
    left: 0px;
    top: 0px;
    width:100%;
    background: rgb(0,0,0);
    background: linear-gradient(45deg, rgba(0,0,0,1) 0%, rgba(59,59,59,1) 100%);
    z-index:1001;
}

.ImageGalleryPopUp-img-area
{
    width: 100%;
    display: flex;
    align-items:center;
    justify-content: center;
}

#ImageGalleryPopUp-menu
{
    display: flex;
    flex-direction: row;
    transition: ease 1s;
}

#ImageGalleryPopUp-menu img
{
    height: 150px;
    cursor:pointer;
    filter: grayscale(70%);
}

#ImageGalleryPopUp-menu img:hover
{
    filter: grayscale(0%);
}

.ImageGalleryPopUp-close-btn
{
    position:absolute;
    top: 20px;
    right: 20px;
}

@media only screen and (max-width: 1000px) {

    .ImageGalleryPopUp-img-area img
    {
        width: 100%;
    }

    #ImageGalleryPopUp-menu
    {
        margin-top: 50px;
    }
    #ImageGalleryPopUp-menu img
    {
        max-height: 100px;
    }

    .ImageGalleryPopUp-menu-btn
    {   
        padding: 25px 10px;    
    }
    
    i {
        font-size: 36px;
    }
}
</style>