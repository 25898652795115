<script>
import MobileMenu from '@/components/MobileMenu.vue'
import Logo from '@/components/Logo.vue'
import menuLinks from "@/helpers/menuLinks"
export default {
    components: { MobileMenu, Logo },
    props: ['positionLeft', 'positionWidth'],
    data(){        
        return {
            customer: this.$store.state.customer,
            adminPages: menuLinks.hideMenuPages
        }
    },
    computed:
    {
        user()
        {
            return this.$store.state.user;
        },
        isAdmin()
        {
            return this.user.user_level == 'SamfallifyCrew' || this.user.user_level == 'Redaktör'
        },
        currentRouteName() {
            return this.$route.name;
        },
        isInAminMode() {
            return this.adminPages.some(p => p == this.$route.name);
        },
        noOfUserMessages() {  
            var self = this;
            if(this.$store.state.userMessages == null)
            {
                return 0;
            }
            else{
                var noOfMessages = 0;
                this.$store.state.userMessages.forEach(function(message) {
                    if(!message.is_read && message.created_by_user.user_id != self.user.id)
                    {
                        noOfMessages++;
                    }

                    if(message.replys.some(x => x.created_by != self.user.id && !x.is_read))
                    {
                        noOfMessages++;
                    }
                })
                return noOfMessages;
            }
        }        
    },
    created()
    {
        setTimeout(() => {    
            $("#UserMenu-menu").addClass('menu-visible');
        }, 500);
        this.$cache.createCache('userMessages', 'user-messages/');
    }
}
</script>
<template>
  <section class="pc-only" id="UserMenu-menu" :style="{ left: positionLeft + 'px', width: positionWidth + 'px' }">
    <ul class="wow-color border-color" :class="{ 'btn-admin': isAdmin }">
        <li>Inloggad som <b>{{ user.name }}</b> <b v-if="isAdmin">({{ user.user_level }})</b></li>
        <li v-if="!customer.disable_user_messages">
            <router-link to="/user-messages" v-tooltip="'Dina meddelanden'">
                <i class="fa-solid fa-envelope" :style="{ 'color': isAdmin ? 'black' : '' }"></i>
                <p v-if="noOfUserMessages > 0" class="UserMenu-no-of-new-messages"> {{ noOfUserMessages }}</p>
            </router-link>
        </li>
        <li v-if="isAdmin && !isInAminMode"><router-link to="/admin" v-tooltip="'Visa redigeringsläge'"><i class="fa-solid fa-user-pen" style="color: black"></i></router-link></li>
        <li v-if="isAdmin && isInAminMode"><router-link to="/" v-tooltip="'Visa användarläge'"><i class="fa-solid fa-user" style="color: black"></i></router-link></li>
        <li><router-link to="/user-settings" v-tooltip="'Dina inställningar'"><i class="fa-solid fa-gears" :style="{ 'color': isAdmin ? 'black' : '' }"></i></router-link></li>
        <li><router-link to="/logout" v-tooltip="'Logga ut'"><i class="fa-solid fa-door-open" :style="{ 'color': isAdmin ? 'black' : '' }"></i></router-link></li>
    </ul>
    <div class="UserMenu-logo">     
        <p class="UserMenu-customer-name" :class="isInAminMode ? 'UserMenu-customer-name-admin' : ''">{{customer.name}}</p>
    </div>
  </section>
  <div class="mobile-only">
        <MobileMenu :noOfUserMessages="noOfUserMessages"></MobileMenu>    
  </div>
   
</template>
<style scoped>
    section
    {
        position:fixed;
        top: -100px;
        width: 100%;
        z-index:40;
    }

    .menu-visible {
        transition-timing-function: ease-in;
        transition: 0.4s;
        opacity:1;
        top: 0px;
    }

    .UserMenu-customer-name {
        margin: 20px 0px 0px 150px;  
        transition: ease-in 0.2s;    
    }

    .UserMenu-logo {
        margin: 5px 0px 0px 10px;
        text-align: left;      
        font-family: "Fredoka", sans-serif;
        font-weight: 700;
        text-transform: uppercase;
        font-size: 16px;
        color: white;
        padding:2px 0px;
        text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
        position: relative;
        width: fit-content;
    }

    .UserMenu-customer-name-admin {
        margin: 20px 0px 0px 10px !important;
        transition: ease-in 0.2s;
    }

    .fa-house{
        font-size:30px;
        padding-right:10px;
    }
    ul{
        float: right;
        list-style: none;
        padding: 11px 0px 8px 14px;
        margin: 17px 38px 10px 23px;
        border-radius:16px;
        color: rgba(50, 50, 50, 0.9);
        border-radius: 16px;
        border: 1px solid rgba(255, 255, 255, 0.3);
        box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15), 0 2px 6px rgba(0, 0, 0, 0.05);
        background-color: hsla(41, 70%, 98%, 0.85);
        backdrop-filter: blur(12px) saturate(150%);
    }

    .fa-gears, .fa-door-open
    {
        font-size: 20px;
        /* text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000; */
    }
    
    .fa-gears:hover, .fa-door-open:hover
    {
        font-size: 20px;
    }

    li{
        display: inline-block;
        margin-right: 15px;
    }
    .UserMenu-no-of-new-messages
    {
        background-color: #00ffa1;
        padding: 0px 2px;
    border-radius: 12px;
    font-size: 11px;
    width: 18px;
    top: 9px;
    position: absolute;
    margin-left: 7px;
    }
</style>