<script>
import PopUp from '@/components/PopUp.vue'
import message from '@/helpers/message'
import Form from '@/components/Form/Form.vue'
import CalendarDays from '@/components/Calendar/CalendarDays.vue'
import AdminBookingsBlock from '@/views/Admin/AdminBookingsBlock.vue'
export default {
    components:{ PopUp, Form, AdminBookingsBlock,CalendarDays },
    data() {
        return {          
            bookings: [],
            blockings: [],
            isCancelingBooking: false,
            selectedDay: { day: null, date: null, isBlocked: false },
            bookingItem: null,
            selectedItem: null,
            blockingPopup: { isOpen: false },
            infoFormPublic: [                   
                { sections: [{ sectionName: 'Information om bokaren', classes: "g-100", inputs: [ 
                            { type: 'text', prop: 'full_name', title: 'Namn', classes: "g-50", required: false },
                            { type: 'switch', prop: 'is_public_booking', title: 'Bokad via publika sidan', classes: "g-50", required: false },
                            { type: 'text', prop: 'email', title: 'Email', classes: "g-50", required: false },
                            { type: 'text', prop: 'mobile_no', title: 'Mobilnummer', classes: "g-50", required: false },
                            { type: 'switch', prop: 'send_reminder_to_mobile_no', title: 'Använder SMS för bekräftelse och påminnelse', classes: "g-100", required: false },
                        ]
                    }, { sectionName: 'Längd på bokningen', classes: "g-100", inputs: [
                        { type: 'select', prop: 'duration', title: '', classes: "g-100", required: false}, 
                        ]
                    }
                    ],
                        backgroundColor: this.$colors.form.backgroundColor,
                        borderColor: this.$colors.form.borderColor
                }],
            infoFormInternal: [                   
                { sections: [{ sectionName: 'Information om bokaren', classes: "g-100", inputs: [ 
                            { type: 'text', prop: 'full_name', title: 'Namn', classes: "g-50", required: false },
                            { type: 'switch', prop: 'is_public_booking', title: 'Bokad via publika sidan', classes: "g-50", required: false }                          
                        ]
                    }, { sectionName: 'Längd på bokningen', classes: "g-100", inputs: [
                        { type: 'select', prop: 'duration', title: '', classes: "g-100", required: false}, 
                        ]
                    }
                    ],
                        backgroundColor: this.$colors.form.backgroundColor,
                        borderColor: this.$colors.form.borderColor
                }]
        };
    },
    computed: {      
        startTime() {           
           return parseInt(this.bookingItem.day_available_start_time.split(':')[0]);
       },
        endTime() {           
           return parseInt(this.bookingItem.day_available_end_time.split(':')[0]);
       },
       selectedDayBookings() {
            if(this.selectedDay.day == null) {
                return [];
            }

            return this.getBookingsForDay();
       }     
    },
    created() {
        this.getBookingItem();
        this.getBookings();
    },
    methods: {       
        getBookings()
        {
            var self = this;
            this.$communicator.get('bookings/'+this.$route.params.id +'/log')
            .then(function(data)
            {                 
                self.bookings = data;
            })
            .fail(function(error) {                  
            })  
            
            this.$communicator.get('bookings/'+this.$route.params.id +'/blockings')
            .then(function(data)
            {                 
                self.blockings = data;
            })
            .fail(function(error) {                  
            })  
        },     
        getBookingItem()
        {
            var self = this;
            this.$communicator.get('available-bookings/'+this.$route.params.id)
            .then(function(data)
            {                 
                self.bookingItem = data;
            })
            .fail(function(error) {                  
            })  
        },     
        cancelBooking()
        {
            var self = this;
            this.$communicator.post('cancel-booking/', this.selectedItem)
            .then(function(data)
            {          
                message.setSuccessMessage('Avbokningen lyckades och ett avbokningsmail är skickat.');   
                self.selectedItem = null;    
                self.getBookings();
            })
            .fail(function(error) {                  
            })  
        }, 
        getBookingsForDay() {
            const year = this.selectedDay.date.getFullYear();
            const month = String(this.selectedDay.date.getMonth() + 1).padStart(2, '0');
            const day = String(this.selectedDay.date.getDate()).padStart(2, '0');
            var dateString = `${year}-${month}-${day}`;
            return this.bookings.filter(booking => booking.formatted_start_date == dateString);
        },      
        createBlocking()
        {
            this.blockingItem = { id: 0, }
        }    
    }
  };
</script>
<template>
    <div v-fade-in  v-if="bookingItem">
        <main class="opacity-admin-bg overflow-auto position-relative">
            <div class="breadcrumbs">
                <router-link :to="{ name: 'admin' }">Startsidan: Redaktör</router-link>
                &nbsp; >>  &nbsp;
                <router-link :to="{ name: 'adminBookings' }">Bokningar</router-link>
                &nbsp; >>  &nbsp;
                <b>Bokningskalendern</b>
            </div>
            <div class="float-right">
                <div class="selected-day blocked-dot"></div> = vald dag &nbsp;<div class="blocked blocked-dot"></div> = blockering &nbsp; &nbsp; &nbsp;
                <button class="btn btn-gray" @click="blockingPopup.isOpen = true">Hantera blockeringar</button>
            </div>
            <h2 class="text-left">Bokningskalender för {{ bookingItem.name }}</h2>
            <div class="calendar g-70">
                <CalendarDays :bookings="bookings" :blockings="blockings" :selectedDay="selectedDay"></CalendarDays>
            </div>
            <div class="g-30">       
                <div style="margin: 104px 0px 0px 20px;" v-if="selectedDay.day" :class="selectedDay.isBlocked ? 'blocked': ''">
                    <div v-for="(no, index) in 25">
                        <div v-if="index >= startTime && index < endTime" class="day-row">
                            <div style="margin-top: -12px">{{ index.toString().padStart(2, '0') }}:00</div>   
                            <div v-for="booking in selectedDayBookings">
                                <div v-if="booking.formatted_start_time == index.toString().padStart(2, '0') + ':00'" class="hour-occupied" :style="{'height': booking.duration_time * 25 + 'px'}">
                                    {{ booking.formatted_start_time }} - {{ booking.formatted_end_time }} {{ booking.full_name }} <button class="btn btn-details float-right" @click="selectedItem = booking">Detaljer</button>
                                </div>    
                            </div>           
                        </div>
                    </div>
                    <div class="day-end-time">
                        <div style="margin-top: -12px">{{endTime}}:00</div>
                    </div>
                </div>
            </div>
        </main>
    </div>
    <PopUp v-if="selectedItem" :width="'800px'" :bgColor="this.$colors.list.backgroundColor">
        <button class="btn btn-gray float-right" @click="selectedItem= null">Stäng</button>
        <div class="text-left">
            <h2>Bokning, {{ selectedItem.formatted_start_date_and_time }} - {{ selectedItem.formatted_end_time }}</h2>
        </div>
        <Form v-if="selectedItem.is_public_booking" :form="infoFormPublic" :item="selectedItem" :readOnly="true"></Form>
        <Form v-else :form="infoFormInternal" :item="selectedItem" :readOnly="true"></Form>

        <button class="btn btn-gray float-right" v-if="!isCancelingBooking" @click="isCancelingBooking = true">Avboka</button>
        <div v-if="isCancelingBooking" class="text-left">
            <label>Skicka med en kommentar som infogas i avbokningsmailet.</label>
            <textarea style="height: 80px" v-model="selectedItem.cancel_comment"></textarea>
            <button class="btn float-right" @click="cancelBooking">Avboka och skicka avbokningsmail</button>
        </div>
    </PopUp> 
    <div v-if="bookingItem">
        <AdminBookingsBlock :blockingPopup="blockingPopup" :blockings="blockings" :getBookings="getBookings" :bookingItemId="bookingItem.id"></AdminBookingsBlock>
    </div>
</template>
  <style scoped>
  .calendar {
    text-align: center;
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;   
  }
  .weekdays, .days {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 5px;
    text-align: center;
  }
  .weekdays div {
    font-weight: bold;
  }
  .day, .empty {
    background: #efdab1;
    padding: 22px 10px 10px 10px;
    border: 1px solid #d3c499;
    height: 73px;
    border-radius: 3px;
    position: relative;
  }

  .day{
    cursor: pointer;
  }

  .day:hover {
    background-color: #f0e4cb !important;
  }

  .day span {
    position: absolute;
    top: 2px;
    left: 2px;
    font-style: italic;
  }

  .sunday {
    color: red;
    }

    .booking {
        font-size: 10px;
        text-align: left;
        padding: 2px 5px;
        margin-bottom: 1px;
        background-color:rgb(245, 150, 150);
        border-radius: 4px;
    }

    .more-bookings {
        font-size: 13px;

    }
    .day-row {
        border-top: 1px dashed #cbcaca;
        text-align: left;
        height:25px;
        position: relative;
    }

    .day-row:hover {
        background-color: #f0e4cb;
    }

    .day-end-time {
        border-top: 1px dashed #cbcaca;
        text-align: left;
    }

    .hour-occupied {
        position: absolute;
    top: 0px;
    left: 55px;
    background-color: rgb(245, 150, 150);
    height: 22px;
    width: 80%;
    border-bottom: 1px solid red;
    font-size: 13px;
    padding: 1px 6px 0px 14px;
    z-index: 1;
    }

    .btn-details {
        border-radius: 6px;
    padding: 2px 10px;
    margin-top: 2px;
    font-size: 12px;
    }

    

    .blocked-dot
    {
        display: inline-block;
        width: 20px;
        height: 20px;
        border-radius: 10px;
        margin-bottom: -4px;
    }
    
    .selected-day {
        background-color: rgb(188 221 124) !important;

    }
  </style>
  