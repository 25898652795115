<script>
import List from '@/components/List/List.vue'

export default {
    components:{ List },
    props: ['selectedLogItem','closeLog'],
    created() {  
      this.getBookingsLog();
    },
    data() {
        return {                       
            columns: [                                        
               
                { name: 'full_name', displayName: 'Bokad av', width: 200, hideFilter: false},
                { name: 'formatted_start_date_and_time', displayName: 'Startdatum', width: 180, hideFilter: false},
                { name: 'formatted_end_date_and_time', displayName: 'Slutdatum', width: 180, hideFilter: false},
                { name: 'duration', displayName: 'Längd', width: 100, hideFilter: false},
                { name: 'is_public_booking', displayName: 'Bokat på den publika sidan', width: 220, isBoolean: true, hideFilter: false},
                { name: 'email', displayName: 'Email', width: 220, hideFilter: false},
                { name: 'mobile_no', displayName: 'Mobilnummer', width: 180, hideFilter: false},
                { name: 'send_reminder_to_mobile_no', displayName: 'Bokningsbekräftelse via sms', width: 230, isBoolean: true, hideFilter: false},
                { name: 'confirmation_and_reminder_sent_to_sms', displayName: 'Påminnelse via sms', width: 220, isBoolean: true, hideFilter: false},
          
            ],
            log: []       
        }
    },   
    methods:
    {
        
        getBookingsLog()
        {
            var self = this;
            this.$communicator.get('bookings/'+this.selectedLogItem.id+'/log')
            .then(function(data)
            {                 
                self.log = data;
            })
            .fail(function(error) {                  
            })  
        }
        
       
    },
    computed:
    {      
       
    }
}
</script>
<template>
    <button class="btn float-right" @click="closeLog">Stäng</button> 
    <h2 class="text-left">Bokningshistorik för  {{ selectedLogItem.name }}</h2>
    <List :items="log" :columns="columns" :noOfFrozenColumns="1" :nameOfItems="'bokningar'"  :backgroundColor="this.$colors.list.backgroundColor" :borderColor="this.$colors.list.borderColor"></List>
</template>
<style scoped>
</style>