<script>
import Date from './Date.vue'
import Select from './Select.vue'
    export default {
        components: {Select, Date},
        props: ['prop', 'item', 'selectItems', 'startWithYears', 'hasValidationErrors', 'startWithDateNow'],
        data() {
            return {           
              date: null,
              time: null,
            }
        },
        methods: {                  
        },
        created()
        {
            if(this.item[this.prop] != null)
            {
                var splittedDate = this.item[this.prop].split('T');
                this.time = splittedDate[1].slice(0, 5)
                this.date = splittedDate[0]
            } 
        },
        watch: {
            time(newvalue)
            {
                this.item[this.prop] = this.date + ' ' + this.time;
            },
            date(newvalue){
                this.item[this.prop] = this.date + ' ' + this.time;
                if(this.time == null)
                {
                    this.time = '00:00';
                }
            }

        }

    }
</script>

<template>
    <div class="g-50">
        <Date v-model:value="date" :prop="prop" :startWithYears="startWithYears" :hasValidationErrors="hasValidationErrors"></Date>
    </div>
    <div class="g-50">
        <Select :prop="prop" v-model:selectedItemSysID="time" :currentItemDisplayName="time" :selectItems="selectItems" :hasValidationErrors="hasValidationErrors"></Select>
    </div>
</template>
<style scoped>

</style>