<script>
import List from '@/components/List/List.vue'
import Form from '@/components/Form/Form.vue'
import PopUp from '@/components/PopUp.vue'
import message from '@/helpers/message'
import config from "@/helpers/config"

export default {
    components:{ List, Form, PopUp },
    props: ['locations', 'getCourses'],
    data() {
        return {             
           locationItem: null,
           isAdding: false,
           form: [                   
            { sections: [
                { sectionName: 'Plats', classes: "g-100", inputs: [{ type: 'text', prop: 'name', title: 'Namn', classes: "g-100", required: true }]
                }]
            }],
            columns: [                             
                { name: 'edit', displayName: '<i class="fa-solid fa-pencil color-black"></i>', title:'Redigera', icon: 'fa-solid fa-pencil', align:'center', function: this.edit, width: 25, hideFilter: true, hideIfEdit: true},    
                { name: 'delete', displayName: '<i class="fa-solid fa-trash color-black"></i>',  title: 'Ta bort', isDelete: true, isNotDeletable: 'is_used_in_courses', align:'center', width: 25, hideFilter: true, hideIfEdit: true},                
                { name: 'name', displayName: 'Namn', width: 200, hideFilter: false},               
                { name: 'formatted_created_date', displayName: 'Skapad', width: 100, hideFilter: true},
                { name: 'created_by_full_name', displayName: 'Skapad Av', width: 200},            
            ] 
        }
    },   
    methods:
    {
        edit(item)
        {
            this.isAdding = false;
            this.locationItem = item;
        },
        add()
        {
            this.isAdding = true;
            this.locationItem = { id: 0, name: null }       
        },  
        remove(id)
        {
            var self = this;
            this.$communicator.post('admin/courses/locations/' + id + '/delete')
            .then(function(data)
            {                        
                self.getCourses();
                message.setSuccessMessage();
            })
            .fail(function(error) {                  
            })  
        },
        reloadCourses(){
            this.locationItem = null;
            this.getCourses();
        }
        
    },
    computed:
    {
        serverUrl() { return config.urlToServer } 
    }
  
}
</script>
<template>
    <button id="List-sort-btn" class="btn float-right" style="margin-top: 7px;" @click="add">Lägg till plats</button>
    <h2>Platser</h2>
    <List :items="locations" :columns="columns" :noOfFrozenColumns="2" :hideExcelExport="true" :height="200" :hideSearch="true" :deleteFunction="remove" :nameOfItems="'platser'" :backgroundColor="this.$colors.list.backgroundColor" :borderColor="this.$colors.list.borderColor"></List>

    <PopUp v-if="locationItem">       
       <h2>{{ isAdding ? 'Lägg till' :'Redigera' }} plats</h2>
       <Form :form="form" :item="locationItem" :url="'admin/courses/locations/'" :getItemFunction="reloadCourses" :inEditMode="true"></Form>
    </PopUp>   
</template>
<style scoped>
    
</style>